import styled, { css } from 'styled-components';
import { Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export const LineRow = styled(Row)`
  border-top: 1px solid #dee2e6;
`;

export const Label = styled.label`
  color: ${props => props.theme.colors.text.grey4};
  font-size: ${props => props.theme.fontSizes.sm};
  padding: 0;
  margin: 0;
`;

export const HeaderSpace = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

export const DetailsSpace = styled.div`
  display: flex;
  gap: 20px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  max-width: 440px;
  height: 56px;

  color: ${({ theme }) => theme.colors.text.black};
  font-size: ${({ theme }) => theme.fontSizes.xl};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: 26px;
  
  ${({ isCursor }) => isCursor && css`
    cursor: pointer;
  `};
`;

export const ServiceGroup = styled.span`
  color: ${({ theme }) => theme.colors.text.darkGrey};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 20px;
`;

export const InfoSpace = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding-top: 12px;

  color: ${({ theme }) => theme.colors.text.grey1_5};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 18px;
`;

export const BillInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  color: ${({ theme }) => theme.colors.text.grey1_5};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 20px;
`;

export const BillDetails = styled.div`
  display: flex;
  gap: 26px;
  margin-top: 18px;
`;

export const BillDetailsText = styled.div`
  color: ${({ theme }) => theme.colors.text.greyHover};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 20px;
`;

export const Price = styled.span`
  margin-bottom: 24px;

  color: ${({ theme }) => theme.colors.text.black};
  font-size: ${({ theme }) => theme.fontSizes.lg};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 23px;
`;

export const Thead = styled.thead`
  border-bottom: 1px solid ${({ theme }) => theme.colors.text.greyDivider};
  height: 42px;
`;

export const TH = styled.th`
  color: ${({ theme }) => theme.colors.text.grey1_5};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 18px;
`;

export const TR = styled.tr`
  height: 70px;

  ${({ border }) => border && css`
    border-bottom: 1px solid ${({ theme }) => theme.colors.text.greyDivider};
  `}
`;

export const TD = styled.td`
  color: ${({ theme }) => theme.colors.text.deepGrey};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  line-height: 20px;

  ${({ bold, theme }) => bold && css`
    font-weight: ${theme.fontWeights.bold};
  `};

  ${({ grey, theme }) => grey && css`
    color: ${theme.colors.text.grey1_5};
  `};

  ${({ blue, theme }) => blue && css`
    color: ${theme.colors.link};
  `};
`;

export const EllipsisText = styled.div`
  max-width: 140px;
  width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const UsageTitle = styled.button`
  display: flex;
  border: none;
  background-color: transparent;
  width: 170px;
  padding: 0;
  margin: 32px 0 16px 0;
  white-space: nowrap;

  color: ${({ theme }) => theme.colors.text.black};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: 20px;
`;

export const ResourceUsageTitle = styled.span`
  margin-bottom: 24px;

  color: ${({ theme }) => theme.colors.text.black};
  font-size: ${({ theme }) => theme.fontSizes.lg};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 23px;
`;

export const CustomTooltipContainer = styled.div`
  margin: 0px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  gap: 6px;

  & > :nth-child(2) {
    color: #0FA1FF !important;
  };
`;

export const CustomDropdown = styled(Dropdown)`
  display: flex;
  justify-content: flex-end;
  width: 98%;
`;

export const CustomDropdownToggle = styled(DropdownToggle)`
  background-color: transparent !important;
  border: none;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  line-height: 20px;
  color: ${({ theme }) => theme.colors.link};
  padding: 0;

  &:hover, &:focus, &:active{
    color: ${({ theme }) => theme.colors.link} !important;
    box-shadow: none !important;
  };
  img {
      transition: all .5s;
      transform: ${({ isOpen }) => isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
    };
`;

export const CustomDropdownToggleImg = styled.img`
  transition: transform .5s ease;
  transform: ${(isOpen) => isOpen ? 'rotate(180deg)' : 'rotate(0)'};
`;

export const CustomDropdownMenu = styled(DropdownMenu)`
  left: -60px !important;
  min-width: 116px;
  box-shadow: 0px 20px 32px 0px #484F561A, 0px 2px 8px 0px #7F878D14;
  padding: 6px;
  
  box-sizing: border-box;
  max-height: 112px;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: 6px;

  ::-webkit-scrollbar {
      width: 6px;
  }
`;

export const CustomDropdownItem = styled(DropdownItem)`
  border-radius: 6px;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 20px;
  height: 28px;

  &:hover {
    background-color: transparent !important;
    color: ${({ theme }) => theme.colors.secondaryActive};
  }

  ${({ selected }) => selected && css`
    background-color: ${({ theme }) => theme.colors.background.blue};
    color: ${({ theme }) => theme.colors.secondaryActive};

    &:hover {
      background-color: ${({ theme }) => theme.colors.background.blue} !important;
      color: ${({ theme }) => theme.colors.secondaryActive};
    }
  `};
`;