import { __env } from '../../envloader';
import { UserRole } from '../constants/roles';
import axios from '../../main/utils/axios/axiosInstance';

const namespace = '@PRACELAB_SPACES/';

export const FETCH_SPACE_LIST_REQUEST = namespace + 'FETCH_SPACE_LIST_REQUEST';
export const FETCH_SPACE_LIST_SUCCESS = namespace + 'FETCH_SPACE_LIST_SUCCESS';
export const FETCH_SPACE_LIST_ERROR = namespace + 'FETCH_SPACE_LIST_ERROR';
export const FETCH_SPACE_LIST_STOP_LOADING = namespace + 'FETCH_SPACE_LIST_STOP_LOADING';

export const FETCH_OWN_ROLES_REQUEST = namespace + 'FETCH_OWN_ROLES_REQUEST';
export const FETCH_OWN_ROLES_SUCCESS = namespace + 'FETCH_OWN_ROLES_SUCCESS';
export const FETCH_OWN_ROLES_ERROR = namespace + 'FETCH_OWN_ROLES_ERROR';
export const FETCH_OWN_ROLES_STOP_LOADING = namespace + 'FETCH_OWN_ROLES_STOP_LOADING';

export const FETCH_SPACE_IMG_REQUEST = namespace + 'FETCH_SPACE_IMG_REQUEST';
export const FETCH_SPACE_IMG_SUCCESS = namespace + 'FETCH_SPACE_IMG_SUCCESS';
export const FETCH_SPACE_IMG_ERROR = namespace + 'FETCH_SPACE_IMG_ERROR';

export const getSpaceListWithUsers = () => async (dispatch, getState) => {
  try {
    const currentData = getState()?.spaces?.spaceList?.get('data');
    dispatch({ type: FETCH_SPACE_LIST_REQUEST });
    const { data } = await axios.get(`${__env.USERS_DATA_API_URL}api/users-data/spaces/detailed?sort=name:asc`);

    if (data.matching_count === 0) {
      dispatch({ type: FETCH_SPACE_LIST_SUCCESS, payload: [] });
    } 
    else {
      const roles = getState().spaces.ownRoles.get('data');
      const spaceList = await Promise.all(data.items.map(async (space) => {
        const spaceRoles = roles.filter(({ space_id }) => space_id === space.id).map(({ role }) => role);
        const rolesToCheck = [ UserRole.OWNER, UserRole.USER_ADMIN, UserRole.FINANCIAL_ADMIN ];
        
        if (spaceRoles.some(role => rolesToCheck.includes(role))) {
          const { data: users } = 
            await axios.get(`${__env.USERS_DATA_API_URL}api/users-data/spaces/${space.id}/users`);
          return {
            ...space,
            users: users.items,
          };
        }
        else {
          return {
            ...space,
          };
        }
      }));
      if (JSON.stringify(currentData) !== JSON.stringify(spaceList)) {
        dispatch({ type: FETCH_SPACE_LIST_SUCCESS, payload: spaceList });
      }
      else {
        dispatch({ type: FETCH_SPACE_LIST_STOP_LOADING });
      }
    }
  }
  catch (err) {
    dispatch({ 
      type: FETCH_SPACE_LIST_ERROR, 
      payload: err.response ? err.response.data : err.message
    });
  }
};

export const getOwnRoles = (refreshToken = false) => (dispatch, getState) => {
  const currentData = getState()?.spaces?.ownRoles?.get('data');

  dispatch({ type: FETCH_OWN_ROLES_REQUEST });
  return axios.get(`${__env.USERS_DATA_API_URL}api/users-data/spaces/own_roles/`, { __refetchToken: refreshToken })
    .then(response => {
      if (JSON.stringify(currentData) !== JSON.stringify(response.data?.items)) {
        dispatch({ type: FETCH_OWN_ROLES_SUCCESS, payload: response.data.items });
      }
      else {
        dispatch({ type: FETCH_OWN_ROLES_STOP_LOADING });
      }
    })
    .catch(err => {
      dispatch({ 
        type: FETCH_OWN_ROLES_ERROR, 
        payload: err.response ? err.response.data : err.message
      });
    });
};

export const getSpaceImageList = () => async (dispatch) => {
  dispatch({ type: FETCH_SPACE_IMG_REQUEST });
  try {
    let { data } = await axios.get(`${__env.SERVICE_DATA_API_URL}api/user_contract_categories`);
    dispatch({
      type: FETCH_SPACE_IMG_SUCCESS,
      payload: data?.items
    });
  } 
  catch (err) {
    dispatch({
      type: FETCH_SPACE_IMG_ERROR,
      payload: err.response ? err.response.data : err.message
    });
  }
};

