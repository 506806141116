import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Alert } from 'reactstrap';

import CreateContract from '../../components/CreateContract';
import Space from '../Space';
import ContractInProgress from '../../components/ContractInProgress';
import Loading from '../../../main/components/loading';
import { __env } from '../../../envloader';
import useAxios from '../../hooks/useAxios';
import { Container } from '../../styles/styled-components/GlobalStyles';
import { AppTitle } from '../../utils/componentsLocal';

const SpaceList = () => {
  const spaceList = useSelector((state) => state.spaces.spaceList.get('data'));
  const spaceListLoading = useSelector((state) => state.spaces.spaceList.get('isLoading')); 
  const spaceListError = useSelector((state) => state.spaces.spaceList.get('error')); 
  const ownRolesLoading = useSelector((state) => state.spaces.ownRoles.get('isLoading'));
  const { 
    data: contracts, 
    isLoading: contractsLoading, 
    error: contractsError 
  } = useAxios(__env.BPM_API_URL + 'contracts');
  const { t } = useTranslation();

  const filterContractsInProgress = contracts?.filter(
    (contract) =>
      contract?.current_step !== contract?.total_steps ||
      contract?.final_step_result !== "COMPLETED"
  );

  return (
    <div>
      <Helmet>
        <title>{t('page_titles.home', { USERS_UI_NAME: AppTitle() })}</title>
      </Helmet>
      <Container>
        {
          contractsLoading ?
            ( 
              <Loading />
            ) : (
              contractsError ? (
                <Alert color='danger'>{t('space_list.contracts_in_progress_error')}</Alert>
              ) : (
                filterContractsInProgress?.length > 0 &&
                  <section className="mt-5">
                    <h2 className='mb-5 font-xl fontWeight-bold'>{t('space_list.contracts_in_progress')}</h2>
                    <ul>
                      {filterContractsInProgress.map(contract => <ContractInProgress key={contract.processName} contract={contract} />)}
                    </ul>
                  </section>
              )
            )
        }
        <section>
          <h2 className='mt-4 mb-5 font-xl fontWeight-bold'>{t('space_list.my_spaces')}</h2>
          {ownRolesLoading || spaceListLoading ? (
            <Loading />
          ) : (
            spaceListError ? (
              <Alert color='danger'>{t('space_list.space_list_error')}</Alert>
            ) : (
              spaceList.length === 0 ? (
                <div>
                  <p className='mb-0'>
                    {t("space_list.empty_space_list_message")}
                  </p>
                  <p>
                    {t("space_list.you_can_create_new_space")}    
                  </p>
                  <CreateContract />
                </div>
              ) : (
                <ul>
                  {spaceList.map(space => 
                    <Space 
                      key={space.id} 
                      space={space} 
                    />)}
                </ul>
              )
            )
          )}
        </section>
      </Container>
    </div>
  );
};

export default SpaceList;
