import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';

import { Container, CustomCard, CustomCardTitle, IconContainer } from '../styles/styled-components/ServiceMethodOfUse';
import { defaultIcon, IconDoc, IconQcg, IconSsh } from '../../assets/svg/services/icons';
import backendTranslation from './../utils/backendTranslation';

const ServiceMethodOfUse = ({ locations }) => {
  const CardIcons = {
    ICON_DOC: IconDoc,
    ICON_SSH: IconSsh,
    ICON_QCG: IconQcg,
  };

  const [ isTextOverflow, setIsTextOverflow ] = useState(false);

  const checkTextOverflow = (element) => {
    const isEllipsis = element.offsetHeight < element.scrollHeight;
    setIsTextOverflow(isEllipsis);
  };

  const handleCardClick = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  if (!locations?.items?.length) {
    return null;
  }

  return (
    <Container>
      {locations?.items?.map((location, index) => 
        <CustomCard key={index} onClick={() => handleCardClick(location?.url)}>
          <IconContainer>
            {React.createElement(CardIcons[location?.icon?.id] || 'img', { src: location?.icon?.data || defaultIcon })}
          </IconContainer>
          <CustomCardTitle id={`CardTitle-${index}`} onMouseEnter={(event) => checkTextOverflow(event.target)}>
            {backendTranslation(location?.description)}
          </CustomCardTitle>
          { isTextOverflow &&
            <UncontrolledTooltip
              placement="bottom"
              target={`CardTitle-${index}`}
            >
              {backendTranslation(location?.description)}
            </UncontrolledTooltip>
          }
        </CustomCard>
      )}

    </Container>
  );
};

export default ServiceMethodOfUse;

ServiceMethodOfUse.propTypes = {
  locations: PropTypes.object
};