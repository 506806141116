import styled, { keyframes } from 'styled-components';

const fadeInOutAnimation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const Loader = styled.div`
  position: relative;
  width: 100%;
  margin: ${({ tiny }) => (tiny ? '0' : '3rem 0')};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1501;
`;

export const Dot = styled.div`
  position: absolute;
  width: ${({ tiny }) => (tiny ? '4px' : '7px')};
  height: ${({ tiny }) => (tiny ? '4px' : '7px')};
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 50%;
  transform: ${({ angel, tiny }) =>`rotate(${angel}deg) translate(${tiny ? '9px' : '18px'}) rotate(-${angel}deg)`};
  animation: ${fadeInOutAnimation} 1s linear infinite;
  animation-delay: ${({ delay }) => `${delay}s`};
  opacity: 0.5;
`;