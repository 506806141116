import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import ReduxToastr from 'react-redux-toastr';

import Root from './containers/root';
import configureStore from './stores/configureStore';
import ThemeProvider from '../users-ui/styles/styled-components/Theme';
import { injectStore } from './utils/axios/axiosInstance';
import ScrollToTop from '../users-ui/components/ScrollToTop';

export const { history, store } = configureStore();

injectStore(store);

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ScrollToTop />
      <div data-testid='app-test' style={{ height: "100%" }}>
        <Suspense fallback={<div></div>}>
          <ThemeProvider>
            <Root />
          </ThemeProvider>
          <ReduxToastr
            timeOut={0}
            newestOnTop={true}
            preventDuplicates
            removeOnHover={false}
            removeOnHoverTimeOut={10000}
            position="bottom-center"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            //progressBar
            closeOnToastrClick={true}
          />
        </Suspense>
      </div>
    </ConnectedRouter>
  </Provider>
);

export default App;
