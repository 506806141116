import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Alert } from 'reactstrap';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { BtnBlue, BtnRed } from '../styles/styled-components/Button';
import axios from '../../main/utils/axios/axiosInstance';
import * as notify from '../../main/utils/notify';
import Stepper from '../components/Stepper';
import { getSpaceListWithUsers, getOwnRoles } from '../actions/spacesActions';
import Form from '../components/jsonschema-form/Form';
import { accessTokenProvider } from '../../main/utils/axios/axiosInstance';
import { __env } from '../../envloader';
import {
  StyledProcessForm,
  LastStep,
  LastStepContainer,
  LastStepTitle,
  LastStepTitleText,
  LastStepTitleSubText,
  LastStepImage,
  Title
} from '../styles/styled-components/ProcessForm';
import Theme from '../styles/styled-components/Theme';
import parseIfString from '../utils/objectUtils';
import backendTranslation from '../utils/backendTranslation';
import { getServices } from '../actions/servicesActions';

import processAccept from '../../assets/svg/notifactions/contract-accept.svg';
import processReject from '../../assets/svg/notifactions/contract-reject.svg';
import { BtnBlueOutline } from '../styles/styled-components/Button';
import UsersActionModal from '../components/UsersActionModal';
import { HiglightedText } from '../styles/styled-components/UsersActionModal';
import useCurrentSpace from '../hooks/useCurrentSpace';
import Breadcrumbs  from './spaces/Breadcrumbs';
import Loading from '../../main/components/loading';
import { TileCard } from '../styles/styled-components/TileCard';
import { AppTitle } from '../utils/componentsLocal';

const ProcessForm = ({ title, stepDataURL, onProcessSuccessRedirectUrl, processType, refreshType }) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ formData , setFormData ] = useState({});
  const [ isProcessing, setIsProcessing ] = useState(false);
  const [ processDetails, setProcessDetails ] = useState(null);
  const [ error, setError ] = useState(null);
  const [ otpLoading, setOtpLoading ] = useState(false);
  const [ offerData, setOfferData ] = useState(null);
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepData = useMemo(() => _.get(processDetails, 'data.actionRequired', undefined), [ processDetails ]);
  const history = useHistory();
  const match = useRouteMatch();
  const { space } = useCurrentSpace(match);
  const spaceId = match.params.id;
  const offer_id = processDetails?.data?.offer_id;
  const processResult = processDetails?.data?.final_step_result;
  const [ isProcessCompleted, isProcessCancelled, isFinalStepError, isSubfinalStepError, isFinalOrSubfinalStep ] = [
    processResult === 'COMPLETED',
    processResult === 'CANCELLED',
    processResult === 'FAILED',
    processResult === 'SUBFINAL' && processDetails?.data?.subfinal_step_result === 'FAILED',
    processResult !== 'NONE'
  ];
  const procesError = isFinalStepError || isProcessCancelled || isSubfinalStepError;
  const isFinalStep = (isProcessCompleted || isFinalStepError || isProcessCancelled);
  const canCancel = processDetails?.data?.user_can_cancel;
  const apiProcessType = `${processType}s`;
  const { data: { ui_feedback: info } = {} } = processDetails ?? {};
  const spaceLocation = `/spaces/${space?.id}`;

  const dataLocations = useMemo(() => ([
    { title: space?.name, location: spaceLocation },
    { title: t('nav_items.offers'), location: `${spaceLocation}/offers` },
    { title: backendTranslation(offerData?.offer__name), location: `${spaceLocation}/offers/${processDetails?.data?.offer_id}` }
  ]), [ space?.name, spaceLocation, t, processDetails?.data?.offer_id, offerData?.offer__name ]);

  useEffect(() => {
    setFormData((stepData && stepData.formData) ? parseIfString(stepData.formData) : {});
  }, [ stepData ]);

  useEffect(() => {
    if (offer_id){
      axios.get(`${__env.SERVICE_DATA_API_URL}api/user_offers_current/${offer_id}`).then(({ data: { item } }) => {
        setOfferData(item);
      }).catch(function (error) {
        notify.error("", t('offers.notifications.error_offer_details'));
      }); 
    }
  }, [ offer_id, t ]);

  const notifyProcessEndSuccess = useCallback(() => {
    notify.info(
      t('process_form.notifications.process_success_title'),
      t('process_form.notifications.process_success_body')
    );
  }, [ t ]);

  const redirectWhenGone = useCallback(() => {
    history.push(onProcessSuccessRedirectUrl);
    notifyProcessEndSuccess();
  }, [ onProcessSuccessRedirectUrl, notifyProcessEndSuccess, history ]);

  const getProcessDetails = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(stepDataURL);
      setIsLoading(false);
      setProcessDetails(response);
    } 
    catch (err) {
      setIsLoading(false);
      if (err.response?.status === 410) {
        redirectWhenGone();
      }
      else {
        setError(t('process_form.could_not_get_contract_details'));
      }
    }
  }, [ stepDataURL, t, redirectWhenGone ]);

  useEffect(() => {
    let timeout = null;
    if (processDetails && !processDetails.data?.actionRequired) {
      timeout = setTimeout(() => getProcessDetails(), 3000);
    }
    if (processDetails?.data.otp_form === false) {
      setTimeout(() => {
        getProcessDetails();
      }, 15000);
    }
    return () => clearTimeout(timeout);
  }, [ processDetails, getProcessDetails ]);

  useEffect(() => {
    getProcessDetails();
  }, [ getProcessDetails ]);

  const notifyProcessStepSuccess = () => {
    notify.info(
      t('process_form.notifications.step_push_success_title'),
      t('process_form.notifications.step_push_success_body')
    );
  };

  const resendOTPCode = () => {
    setOtpLoading(true);
    axios.put(`${__env.BPM_API_URL}affiliations/resend_otp/${processDetails?.data?.processName}`, {})
      .then(() => {
        notify.info("", t('process_form.otp.resend_code_success'));
        getProcessDetails();
        setOtpLoading(false);
      })
      .catch(() => notify.error("", t('process_form.otp.resend_code_error')));
  };

  const submitForm = form => {
    setError(null);
    setFormData(form.formData);
    setIsProcessing(true);
    const data = {
      stepId: stepData.stepId,
      formData: JSON.stringify(form.formData),
    };
    notify.clean();
    axios.put(__env.BPM_API_URL + stepData.responseUrl, data, { __silentFor: [ { status: 400 }, { status: 403 } ], })
      .then(async () => {
        if (!isFinalStep) {
          notifyProcessStepSuccess();
          getProcessDetails();
        }
        else {
          if (!isFinalStepError) {
            notifyProcessEndSuccess();
            await accessTokenProvider.getInstance().obtainToken(true);
            dispatch(getOwnRoles());
            dispatch(getSpaceListWithUsers());
          }
          dispatch(getServices(spaceId));
          history.push(onProcessSuccessRedirectUrl);
        }
      })
      .catch(error => {
        if (error.response.status === 410) {
          redirectWhenGone();
        } 
        else {
          //this ensures that the form value is kept after error
          setFormData(form.formData);
          if (error?.response?.data?.message){
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setError(backendTranslation(error.response.data.message));
          }
          else {
            notify.error(
              t('process_form.notifications.step_push_error_title'),
              t('process_form.notifications.step_push_error_body')
            );
          }
        }
      }).finally(() => setIsProcessing(false));
  };

  const cancelProcess = () => {
    setIsProcessing(true);
    axios.delete(`${__env.BPM_API_URL}${apiProcessType}/abort_process/${processDetails.data.processName}`)
      .then(() => {
        notify.error("", t('process_form.notifications.process_cancel_success'));
        getProcessDetails();
      })
      .catch(() => notify.error("", t('process_form.notifications.process_cancel_error')))
      .finally(() => setIsProcessing(false));
  };

  const modalAction = (accepted) => {
    if (accepted) {
      cancelProcess();
    };
    setIsModalOpen(false);
  };

  const modalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const ProcessStep = t('process_form.step');
  const CurrentStep = processDetails?.data.current_step;
  const TotalSteps= processDetails?.data.total_steps;
  const StepName = backendTranslation(processDetails?.data?.step_name);

  const switchStatus = () => {
    if (isFinalStepError) {
      return t(`process_form.${processType}.rejected`);
    } 
    else if (isProcessCancelled) {
      return t(`process_form.${processType}.cancelled`);
    } 
    else if (refreshType) {
      return t(`process_form.${processType}.extended`);
    } 
    else {
      return t(`process_form.${processType}.accepted`);
    }
  };

  return (
    <>
      { processDetails?.data?.offer_details && <Breadcrumbs  dataLocations={dataLocations} />}
      <UsersActionModal
        isOpen={isModalOpen}
        cancelButtonText={t('process_form.modal.cancel')}
        confirmButtonText={t('process_form.modal.accept')}
        confirmButtonColor="red"
        bodyText={
          <Trans i18nKey="process_form.modal.body">
            Attention! All previously entered <b>information will be deleted</b>. Confirm if you want to continue.
          </Trans>
        }
        toggle={modalAction}
      >
        <Trans i18nKey="process_form.modal.title">
          Are you sure you want to exit<br /> and <HiglightedText>delete</HiglightedText> the information?
        </Trans>
      </UsersActionModal>
      <Helmet>
        <title>{t(`page_titles.${processType}_in_progress`, { USERS_UI_NAME: AppTitle() })}</title>
      </Helmet>
      <StyledProcessForm finalStepError={isFinalStepError}>

        { error && <Alert color='danger'>{error}</Alert> }

        { processDetails && (
          <>
            <div className="d-flex justify-content-between align-items-center pb-3 mt-4" >
              <Title>{backendTranslation(processDetails.data.process_title) || title}</Title>
              {
                canCancel &&
                <BtnRed
                  onClick={() => modalToggle()}
                  outline
                  disabled={isProcessing || isLoading}
                >
                  {t('process_form.cancel')}
                </BtnRed>
              } 
            </div>
            <div style={{ margin: "34px 0 32px 0" }}>
              <Stepper
                currentStep={CurrentStep}
                totalSteps={TotalSteps}
              />
              {
                // display step_name (or nothing if jsonSchema contains non-empty title)
                !(stepData?.jsonSchema && parseIfString(stepData.jsonSchema).title) &&
                <h2 style={{ marginTop: "50px" }}>{`${ProcessStep} ${CurrentStep} : ${StepName}`}</h2>
              }
            </div>
            { (stepData?.jsonSchema) ? (
              <Form
                style={{ width: "844px" }}
                schema={backendTranslation(stepData?.jsonSchema)}
                uiSchema={backendTranslation(parseIfString(stepData.uiSchema))}
                formData={formData}
                onSubmit={submitForm}
                onError={() => notify.error('', t('process_form.form_error'))}
                disabled={isProcessing || isLoading}
                resendButton={
                  processDetails?.data?.otp_form !== undefined && (
                    otpLoading 
                      ? <Loading />
                      :
                      <BtnBlueOutline 
                        type="button" 
                        className="float-left" 
                        disabled={!processDetails.data.otp_form} 
                        onClick={resendOTPCode}
                      >
                        {t('process_form.otp.resend_code')}
                      </BtnBlueOutline>
                  )
                }
              >
                <BtnBlue type="submit" className="float-right" disabled={isProcessing || isLoading}>
                  {t('process_form.submit')}
                </BtnBlue>
              </Form>
            ) : (isFinalOrSubfinalStep && stepData) ? (
              <Theme>
                <LastStep>
                  <LastStepContainer>
                    <LastStepImage src={procesError ? processReject : processAccept} alt="" />
                    {isFinalStep && (
                      <LastStepTitle>
                        {isFinalStep && t(`process_form.${processType}.has_been`)} 
                        <LastStepTitleText procesError={procesError}>
                          {switchStatus()}
                        </LastStepTitleText>
                      </LastStepTitle>
                    )}
                    <LastStepTitleSubText>
                      {backendTranslation(processResult === 'SUBFINAL' ? 
                        stepData.subfinal_step_result_msg
                        : 
                        stepData.final_step_result_msg)
                      }
                    </LastStepTitleSubText>
                  </LastStepContainer>
                  <BtnBlue onClick={() => submitForm({ formData: {} })} className='float-right btn-top' disabled={isProcessing || isLoading}>
                    {t(processResult === 'SUBFINAL' ? 'process_form.submit' : 'process_form.finish')}
                  </BtnBlue>
                </LastStep>
              </Theme>
            ) : (
              <TileCard className="rjsf mt-5">
                <Loading />
                <div style={{ textAlign: 'center' }}>
                  {backendTranslation(info)}
                </div>
              
              </TileCard>
            )}
          </>
        )}
        {         
          // it will only display the spinner when there are forms to fill in. If there are none, then stepData is empty 
          ((isLoading || isProcessing) && stepData) && <Loading />
        }
      </StyledProcessForm>
    </>
  );
};

ProcessForm.propTypes = {
  title: PropTypes.string.isRequired,
  stepDataURL: PropTypes.string.isRequired,
  onProcessSuccessRedirectUrl: PropTypes.string.isRequired,
  processType: PropTypes.string.isRequired,
  refreshType: PropTypes.bool
};

export default ProcessForm;
