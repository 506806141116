import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Card, Alert, Tooltip, Collapse } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import axios from '@axios';

import backendTranslation from '../utils/backendTranslation';
import { MomentLocale } from '../utils/componentsLocal';
import { __env } from '../../envloader';
import axiosInstance from '../../main/utils/axios/axiosInstance';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import * as notify from '../../main/utils/notify';
import useAxios from '../hooks/useAxios';

import { 
  Text,
  TextGrey,
  TextIdService,
  DescriptionPoint,
  Title
} from '../styles/styled-components/ServiceDetails';
import { 
  ErrorMsg,
  EditOpen,
  TextEditTitle,
  SubTextEdit,
  CustomModal,
  CustomBody,
  HR,
  BtnSpace,
} from '../styles/styled-components/RenameModalStyles.js';
import ServiceDetailsForm from './ServiceDetailsForm';
import Loading from '../../main/components/loading';
import ServiceDetailsStatusContainer from './ServiceDetailsStatusContainer';
import InfoBox from './InfoBox';
import ServiceUsageBar from './ServiceUsageBar';

import circleWarningIcon from '../../assets/svg/common/circle-warning.svg';
import serviceCloudUploadIcon from '../../assets/svg/services/service-cloud-upload.svg';
import GreyInfoIcon from '../../assets/svg/common/grey-info.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getServices } from '../actions/servicesActions';
import useCurrentSpace from '../hooks/useCurrentSpace';
import Breadcrumbs  from '../containers/spaces/Breadcrumbs';
import { RoundedInput } from '../styles/styled-components/GlobalStyles';
import { BtnTextBlue, BtnTextGrey } from '../styles/styled-components/Button';
import SanitizedHTML from './SanitizedHTML';
import { openFile } from '../utils/downloadFilesFromIBIS';
import { AngleDown } from '../styles/styled-components/ServiceUsageBar';
import { UserRole } from '../constants/roles';
import { canAccess } from '../utils/rolesUtils';
import { getLogger } from '../../main/utils/logger';
import ServiceMethodOfUse from './ServiceMethodOfUse.jsx';

const ServiceDetails = ({ match }) => {
  const spaceId = match.params.id;
  const { t } = useTranslation();
  const timeoutId = useRef();

  const { roles, space } = useCurrentSpace(match);

  const [ editModalOpen, setEditModalOpen ] = useState(false);
  const [ editValue, setEditValue ] = useState("");
  const [ tooltipOpen, setTooltipOpen ] = useState(false);
  const [ isStatusChanging, setIsStatusChanging ] = useState(false);
  const [ isStatusChangingTrue, setIsStatusChangingTrue ] = useState(false);
  const [ usageData, setUsageData ] = useState();
  const [ isOpen, setIsOpen ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState(false);
  const [ isLoadingConsumptionData, setIsLoadingConsumptionData ] = useState(true);
  const [ lockedStatus, setLockedStatus ] = useState(false);

  const getLastData = (data) => {
    if (data && data.length > 0) {
      return data?.reduce((prev, current ) => {
        return (new Date(prev.date_to) > new Date(current.date_to)) ? prev : current;
      });
    };
    return null;
  };

  const currentData = usageData?.items?.find(item => item?.status === "CURRENT") || getLastData(usageData?.items);

  const dispatch = useDispatch();
  const selectDates = state => ({
    services: state.services.servicesList.get('data'),
    isLoadingServices: state.services.servicesList.get('isLoading'),
  });

  const { services, isLoadingServices } = useSelector(selectDates);
  const userName = useSelector(state => state.login.get("loginData").get("username"));
  const service = services?.filter(s => s.id === match.params.service_id)[0];
  const {
    data: spaceUserOffersData,
    isLoading: isLoadingOffert
  } = useAxios(`${__env.SERVICE_DATA_API_URL}api/spaces/${spaceId}/user_offers`);

  const offerData = spaceUserOffersData?.items?.filter(offer => offer.id === service?.parameters.bpm_params_offer_id.value)[0];

  const dataLocations = useMemo(() => ([
    { title: space?.name, location: `/spaces/${space?.id}` },
    { title: t('nav_items.services'), location: `/spaces/${space?.id}/services` },
    { title: service?.name }
  ]), [ space?.name, space?.id, t, service?.name ]);

  useEffect(() => {
    setIsLoadingConsumptionData(true);
    axios.get(
      `${__env.SERVICE_DATA_API_URL}api/resources_usage/?service_id=${match.params.service_id}`
    ).then(response => {
      setUsageData(response.data);
      setIsLoadingConsumptionData(false);
    });
  }, [ match.params.service_id ]);

  const getServiceStateChange = useCallback(() => {
    axiosInstance.get(
      `${__env.BPM_API_URL}services/is_service_state_changing/${match.params.service_id}`,
    ).then(response => {
      setIsStatusChanging(response.data.value);
      if (response.data.value) {
        setIsStatusChangingTrue(true);
      } 
      else {
        // Refresh data after status update without page refresh
        if (isStatusChangingTrue) {
          dispatch(getServices(spaceId));
          setIsStatusChangingTrue(false);
          notify.success("", t('services_details.status_change.success'));
        }
      }
    });
  }, [ match, isStatusChangingTrue, t, dispatch, spaceId ]);

  useEffect(() => {
    if (isStatusChanging) {
      // Calling getServiceStateChange every 5s as long as status is changing
      const timer = setInterval(() => {
        getServiceStateChange();
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [ isStatusChanging, getServiceStateChange ]);

  useEffect(() => getServiceStateChange(), [ getServiceStateChange ]);

  const hasEndDate = !!service?.parameters?.ui_form_endOfContract?.value;

  // Const data
  const maxLength = 80;

  // Checks if remained less than 15 days
  const endOfContractWarning = hasEndDate &&
    (new Date(service.parameters.ui_form_endOfContract.value) - new Date()) < 1000*60*60*24*15;

  // Set service name in edit input
  useEffect(() => {
    if (editModalOpen) {
      setEditValue(service.name);
    } 
  }, [ offerData, editModalOpen, service?.name ]);

  const pollLockedStatus = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${__env.SERVICE_DATA_API_URL}api/services/${match.params.service_id}/locked/`
      );

      if (data === "false") {
        notify.success("", t("services_details.name_edit.notify.success"));
        setLockedStatus(false);
        dispatch(getServices(spaceId));
      } 
      else {
        timeoutId.current = setTimeout(pollLockedStatus, 10000);
      }
    } 
    catch (error) {
      getLogger({ loggerName: 'Locked Status' }).error(error);
    }
  };
  
  const updateServiceName = () => {
    if (service?.name === editValue) return setEditModalOpen(false);
    if (editValue) {
      axiosInstance
        .patch(`${__env.BPM_API_URL}services/rename_service`, {
          service_id: match.params.service_id,
          name: editValue
        })
        .then(() => {
          setLockedStatus(true);
          setEditModalOpen(false);
          setErrorMessage(false);
          pollLockedStatus();
        })
        .catch(() => notify.error("", t("services_details.name_edit.notify.error")));
    }
    else {
      setErrorMessage(true);
    }
  };
  
  const findVariables = (variable, service) => {
    if (variable === "username") {
      return userName;
    }
    if (service && service.hasOwnProperty(variable)) {
      return service[variable];
    }
    if (service && service.parameters && service.parameters.hasOwnProperty(variable)) {
      return service?.parameters?.[variable]?.value;
    }
  };
  
  const replaceVariables = (string, variable) => {
    return variable ? string.replace(/\{@(.+?)\}/, variable) : string;
  };

  const locationsWithVariables = (locations) => {
    locations?.items?.forEach(item => {
      if (item.url && item.url.includes("{@")) {
        const variable = item.url.match(/\{@(.+?)\}/)?.[1];
        item.url = replaceVariables(item.url, findVariables(variable, service));
      }
      if (item.description && typeof item.description === 'object') {
        Object.keys(item.description).forEach(key => {
          if (item.description?.[key].includes("{@")) {
            const variable = item.description[key].match(/\{@(.+?)\}/)?.[1];
            item.description[key] = replaceVariables(item.description?.[key], findVariables(variable, service));
          }
        });
      }
    });
    return locations;
  };

  useEffect(() => {
    const timeoutIdLocal = timeoutId.current;

    const fetchLockedStatus = async () => {
      try {
        const response = await axiosInstance.get(
          `${__env.SERVICE_DATA_API_URL}api/services/${match.params.service_id}/locked/`
        );
        if (response.data === "true") {
          setLockedStatus(true);
          timeoutId.current = setTimeout(fetchLockedStatus, 10000);
        }
      } 
      catch (error) {
        getLogger({ loggerName: 'Locked Status' }).error(error);
      }
    };
  
    fetchLockedStatus();

    return () => {
      if (timeoutIdLocal) {
        clearTimeout(timeoutIdLocal);
      }
    };
  }, [ match.params.service_id ]);

  useEffect(() => {
    return () => {
      if (timeoutId.current.fetch) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  return (
    <>
      <Breadcrumbs  dataLocations={dataLocations} />
      <Card className='p-4 mt-5'>
        {
          (isLoadingServices || isLoadingOffert)
            ? <Loading />
            : (service 
              ?
              <>
                {
                  service.comment?.pl !== "" &&
                    <InfoBox iconPath={GreyInfoIcon} iconAlt="grey info" color="grey">
                      {backendTranslation(service.comment)}
                    </InfoBox>
                }
                {
                  isStatusChanging && 
                    <InfoBox iconPath={GreyInfoIcon} iconAlt="grey info" color="grey">
                      {t('services_details.status_is_changing')}
                    </InfoBox>
                }
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-column w-75">
                    <div className="d-flex">
                      <img className="mr-4" src={offerData?.offer__tile_icon || serviceCloudUploadIcon} alt="" />
                      <div>
                        <div className="d-flex align-items-center mb-2">
                          <h5 className="font-weight-bold mb-0">{service.name}</h5>
                          {canAccess([ UserRole.FINANCIAL_ADMIN ], roles) && 
                            (lockedStatus
                              ? <div style={{ marginLeft: "1rem" }}><Loading tiny/></div>
                              : (
                                <>
                                  <div>
                                    <EditOpen onClick={() => setEditModalOpen(!editModalOpen)} id="editButton" />
                                  </div>
                                  <Tooltip 
                                    isOpen={tooltipOpen}
                                    toggle={() => setTooltipOpen(!tooltipOpen)}
                                    className="tooltip-normal"
                                    target="editButton"
                                  >
                                    {t('services_details.edit_name')}
                                  </Tooltip>
                                </>
                              )
                            )
                          }
                        </div>
                        {
                          editModalOpen &&
                          <CustomModal isOpen={editModalOpen} > 
                            <CustomBody onClick={e => e.stopPropagation()}>
                              <div>
                                <TextEditTitle>{t('services_details.name_edit.title')}</TextEditTitle>
                                <RoundedInput 
                                  radius="6px" 
                                  error={errorMessage}
                                  style={{ paddingLeft: "12px" }} 
                                  type="text" 
                                  value={editValue} 
                                  onChange={(e) => setEditValue(e.target.value)} 
                                  maxLength={maxLength} 
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter'){
                                      updateServiceName();
                                    }
                                  }
                                  }
                                />
                                { errorMessage && 
                                  <ErrorMsg>{t('services_details.error_message')}</ErrorMsg>
                                }
                                <SubTextEdit>{t('services_details.name_edit.max_chars')}<b>{maxLength}</b></SubTextEdit>
                                <HR/> 
                              </div>
                              <BtnSpace>
                                <BtnTextGrey 
                                  style={{ margin: 0 }} 
                                  onClick={() => { setEditModalOpen(false); setErrorMessage(false); }}
                                >
                                  {t('services_details.cancel')}
                                </BtnTextGrey>
                                <BtnTextBlue style={{ margin: 0 }} primary onClick={() => updateServiceName()}>{t('services_details.change')}</BtnTextBlue>
                              </BtnSpace>
                            </CustomBody>
                          </CustomModal>
                        }
                        { 
                          service?.group && 
                            <TextIdService>{service?.group}</TextIdService>
                        }
                        {
                          hasEndDate &&
                            <div className="d-flex align-items-center">
                              {
                                endOfContractWarning && <img className="mr-2" src={circleWarningIcon} alt=""/>
                              }
                              <TextGrey>
                                {t('services_details.valid_to')}{' '}
                                <MomentLocale format="DD.MM.YYYY" date={service?.parameters?.ui_form_endOfContract.value}/>
                              </TextGrey>
                            </div>
                        }
                      </div>
                      
                    </div>
                    { offerData?.description && 
                      <div className="d-flex justify-content-between align-items-end">
                        <ul className="mt-4 mb-2 w-100">
                          <li className='d-flex'>
                            <DescriptionPoint/>
                            <Text style={{ maxWidth: '90%', margin: "0" }}>
                              <SanitizedHTML html={backendTranslation(offerData?.description)}/>
                            </Text>
                          </li>
                        </ul>
                      </div>
                    }
                  </div>
                  <ServiceDetailsStatusContainer 
                    match={match} 
                    state={service.state}
                    stateId={service.id}
                    serviceUrl={service?.parameters?.SERVICE_URL?.value}
                    getServiceStateChange={getServiceStateChange}
                    setIsStatusChanging={setIsStatusChanging}
                    spaceId={spaceId}
                    isStatusChanging={isStatusChanging}
                    locations={locationsWithVariables(offerData?.locations)}
                  />
                </div>
                <ServiceMethodOfUse locations={locationsWithVariables(offerData?.locations)}/>
                <hr />
                <ServiceDetailsForm serviceData={service} offerData={offerData} isLoadingOffert={isLoadingOffert}/>
                <hr />
                <div>
                  <Title className="mt-2 mb-4">{t('services_details.service_status')}</Title>
                  {
                    isLoadingConsumptionData && <Loading />
                  }
                  {
                    !isLoadingConsumptionData && currentData && currentData?.resource_usage_data.resources.map((ele, idx) => (
                      <ServiceUsageBar key={ele.name} currentData={currentData} data={usageData} type={ele.name} history eleNum={idx} />
                    ))
                  }
                  {
                    isLoadingConsumptionData || (!usageData || usageData?.items?.find(el => el?.resource_usage_data?.resources?.length > 0))
                      ? ''
                      : <div>{t('services_details.no_data')}</div>
                  }
                </div>
                { offerData?.regulations?.items?.length > 0 &&
                  <>
                    <div className='flex-grow-1'>
                      <hr />
                      <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="mb-3 pl-0 border-0 bg-transparent d-flex align-items-center"
                      >
                        <Title>{offerData?.regulations?.items?.length > 1 ? t('nav_items.regimens') : t('regimens')}</Title>
                        <AngleDown className="ml-2" reverse={isOpen ? 1 : 0} />
                      </button>
                      <Collapse isOpen={isOpen}>
                        { offerData.regulations.items.map(item => 
                          <div isOpen={isOpen} className='d-flex align-items-center'>
                            <Text>
                              {backendTranslation(item?.description)}
                              <a 
                                href="/"
                                onClick={(e) => { e.preventDefault(); openFile(item?.url); }}
                                className='pl-2 text-decoration-none text-align-center'
                                rel="noreferrer"
                              > 
                                {t('see_more')}
                              </a>
                            </Text>
                          </div>
                        )
                        }
                      </Collapse>
                    </div>
                  </>
                }             
              </>
              :
              <Alert color="danger">{t('services.service_data_loading_error', { id: match?.params?.service_id })}</Alert>
            )
        }
      </Card>
    </>
  );
};

ServiceDetails.propTypes = {
  match: PropTypes.object.isRequired,
};

export default compose(
  ErrorBoundary((props) => props.t('error_boundary_components.ServiceDetails'))
)(ServiceDetails);
