import i18n from '../../main/utils/i18nConfigProvider';

export const createActionsTable = state => {
  let tempArray = [];
  switch (state) {
  case "INITIALIZED":
    tempArray.push({ value: "ACTIVE", label: i18n.t('services_details.status_actions.activate'), trans: "active" });
    break;
  case "ACTIVE":
    tempArray.push({ value: "PAUSED", label: i18n.t('services_details.status_actions.pause'), trans: "pause" });
    break;
  case "PAUSED":
    tempArray.push({ value: "ACTIVE", label: i18n.t('services_details.status_actions.resume'), trans: "resume" });
    break;
  case "EXPIRED":
    tempArray.push({ value: "TERMINATED", label: i18n.t('services_details.status_actions.terminate'), trans: "terminate" });
    break;
  default:
    break;
  }
  if (tempArray.length > 0) tempArray.push({ value: "EXPIRED", label: i18n.t('services_details.status_actions.expire'), trans: "expire" });

  return tempArray;
};