import React, { useEffect, useMemo, useState } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import Summary from '../../components/settlements/Summary';
import Awaiting from './settlements/Awaiting';
import Reports from './settlements/Reports';
import useCurrentSpace from '../../hooks/useCurrentSpace';
import Loading from '../../../main/components/loading';
import useSpaceBillings from '../../hooks/useSpaceBillings';
import { ErrorBoundary } from '../../../main/hoc/errorboundary';
import { useSelector } from 'react-redux';
import Breadcrumbs  from './Breadcrumbs';
import FiltersPanel from '../../components/settlements/FiltersPanel';
import { noResults, noSettlements } from '../../../assets/svg/settlements/icons';
import { ImgContainer } from '../../styles/styled-components/Settlements';
import { AppTitle } from '../../utils/componentsLocal';

const SpaceSettlements = ({ match }) => {
  const { t } = useTranslation();
  const { space } = useCurrentSpace(match);
  const location = useLocation();

  const [ awaitingData, setAwaitingData ] = useState([]);
  const [ reportsData, setReportsData ] = useState([]);
  const [ filteredBillings, setFilteredBillings ] = useState([]);
  const [ isFilters, setIsFilters ] = useState(false);

  const dataLocations = useMemo(() => ([
    { title: space?.name, location: `/spaces/${space?.id}` },
    { title: t('nav_items.settlements') }
  ]), [ space?.name, space?.id, t ]);

  const selectDates = state => ({
    services: state.services.servicesList.get('data'),
    isLoadingServices: state.services.servicesList.get('isLoading'),
  });

  const { services, isLoadingServices } = useSelector(selectDates);
  const { data: billings, isLoading: billingsIsLoading } = useSpaceBillings(services);

  useEffect(() => {
    setFilteredBillings(billings);
  }, [ billings ]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const filtersValues = searchParams.getAll('filter');
    const searchValue = searchParams.get('search')?.toLowerCase();
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    const convertStartDate = startDate ? new Date(startDate) : null;
    const convertEndDate = endDate ? new Date(endDate) : null;

    if (!filtersValues.length && !searchValue && !startDate && !endDate) {
      setIsFilters(false);
      setFilteredBillings(billings);
    }
    else {
      const filteredData = billings?.filter(billing => {
        const matchesSearchValue = searchValue ? billing?.serviceName?.toLowerCase().includes(searchValue) : true;
        const matchesFilterParams = filtersValues.length ? filtersValues.includes(billing?.serviceName) : true;
        const matchStartDateValue = convertStartDate ? new Date(billing?.creation_date) >= convertStartDate : true;
        const matchEndDateValue = convertEndDate ? new Date(billing?.creation_date) <= convertEndDate : true;

        return matchesSearchValue && matchesFilterParams && matchStartDateValue && matchEndDateValue;
      });
      
      setIsFilters(true);
      setFilteredBillings(filteredData);
    }
  }, [ location.search, billings ]);
  
  useEffect(() => {
    setAwaitingData(filteredBillings?.filter((s) => s.state === "PREPARED" && !s.billing));
    setReportsData(filteredBillings?.filter((s) => ![ "PREPARED", "PREPARING", "CURRENT" ].includes(s.state) || !!s.billing));
  },[ filteredBillings ]);

  if (!space || isLoadingServices || billingsIsLoading) {
    return (
      <>
        <Breadcrumbs dataLocations={dataLocations} />
        <Loading />
      </>
    );
  }

  if (!awaitingData.length && !awaitingData.length && !isFilters) {
    return (
      <>
        <Breadcrumbs dataLocations={dataLocations} />
        <ImgContainer style={{ marginTop: "32px" }}>
          <img src={noSettlements} alt='noSettlements'/>
          <p>{t('settlements.no_settlements')}</p>
          <p>{t('settlements.no_settlements_description')}</p>
        </ImgContainer> 
      </>
    );
  }

  return (
    <>
      <Breadcrumbs dataLocations={dataLocations} />
      <Helmet>
        <title>
          {t("page_titles.spaces.settlements", { space: space.name, USERS_UI_NAME: AppTitle() })}
        </title>
      </Helmet>
      <Card className="p-4 mt-5">
        <FiltersPanel settlements={billings} />
        <Summary servicesLen={services.length} settlements={filteredBillings} />
        {!awaitingData.length && !awaitingData.length && isFilters 
          ? (
            <ImgContainer>
              <img src={noResults} alt='noResults'/>
              <p>{t('settlements.no_results')}</p>
              <p>{t('settlements.try_again')}</p>
            </ImgContainer> 
          )
          : (
            <>
              <Awaiting settlements={awaitingData} isFilters={isFilters}/>
              <Reports settlements={reportsData} isFilters={isFilters}/>
            </> 
          )
        }
      </Card>
    </>
  );
};

SpaceSettlements.propTypes = {
  match: PropTypes.object.isRequired, // HOC
};

export default compose(
  ErrorBoundary((props) => props.t("settlements.error_boundary"))
)(SpaceSettlements);