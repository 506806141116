import styled, { css } from 'styled-components';
import { Modal } from 'reactstrap';

export const ModalFavorite = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    min-width: 696px;
    margin: 0 auto;
`;

export const FMTitle = styled.div`
    display: flex;
    align-items: center;
`;

export const FMTitleText = styled.p`
    margin: 0 0 0 1rem;
`;

export const FMTitleNumber = styled.span`
    color: ${props => props.theme.colors.primary};
`;

export const FMSearchLabel = styled.p`
    color: ${props => props.theme.colors.text.grey0};
    font-weight: ${props => props.theme.fontWeights.medium};
`;

export const FMInputContainer = styled.div`
    position: relative;
    width: 60%;
`;

export const FMInputButton = styled.button`
    position: absolute;
    top: 0;
    right: -1px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.colors.secondary};
    border: 2px solid ${props => props.theme.colors.secondary};
`;

export const FMBody = styled.div`
    height: 478px;
`;

export const FMLastAddedTitle = styled.p`
    color: ${props => props.theme.colors.text.grey0};
    font-weight: ${props => props.theme.fontWeights.bold};
    font-size: ${props => props.theme.fontSizes.sm};
    margin-top: 1rem;
`;

export const FMList = styled.div`
    overflow-y: auto;
    height: 436px;
    scrollbar-width: 11px;
    ::-webkit-scrollbar {
        width: 11px;
    }
`;

export const FMListElement = styled.div`
    display: flex;
    margin-bottom: 1rem;
`;

export const FMListElemenetTitle = styled.p`
    margin: 0 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 480px;
    ${({ isCursor }) => isCursor && css`cursor: pointer`}
`;

export const FMListElementSubTitle = styled.p`
    margin: 0 1rem;
    font-size: ${props => props.theme.fontSizes.sm};
    color: ${props => props.theme.colors.text.lessDarkGrey};
`;

export const FMListElementLink = styled.button`
    border: none;
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.link};
    margin: 0 1rem 0 auto;
    white-space: nowrap;
`;

export const FMNoFavoritesImage = styled.img`
    margin: 4rem 0;
`;

export const FMNoFavoritesTitle = styled.p`
    color: ${props => props.theme.colors.text.lessDarkGrey};
    font-weight: ${props => props.theme.fontWeights.bold};
    margin-bottom: .5rem;
`;

export const FMNoFavoritesSubTitle = styled.p`
    color: ${props => props.theme.colors.text.grey1_5};
    text-align: center;
    font-size: ${props => props.theme.fontSizes.sm};
`;