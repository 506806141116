import React, { useState, Fragment, useEffect } from 'react';
import { Route, Link, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  NavbarBrand,
  Nav,
  Collapse,
  Dropdown,
  DropdownToggle,
  DropdownItem
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import cs from 'classnames';
import FavoriteModal from './spaces/FavoriteModal';

import { getUserPreferences } from '../actions/userPreferencesActions';
import NotificationsIcon from '../components/NotificationsIcon';
import Login from '../../main/containers/login';
import { ColIcon, ColText, DividerElem, DropMenu, ImgElem, RowItem, StyledNavbar, TextValue } from '../styles/styled-components/Topbar';
import TicketsButton from '../components/TicketsButton';
import { StarIcon } from '../styles/styled-components/GlobalStyles';
import caretIcon from '../../assets/svg/common/caret.svg';
import { StarButton } from '../styles/styled-components/FavoriteButton';
import { getSpaceImageList } from '../actions/spacesActions';
import InstitutionLogo from './InstitutionLogo';

const dropdownMenuModifiers = {
  offset: {
    enabled: true,
    offset: '0,16px',
  },
};

const TopBar = () => {
  const [ dropdownOpen, setDropdownOpen ] = useState(false);
  const match = useRouteMatch('/spaces/:id');
  const isAuth = useSelector((state) => state.login.get('loginData'));
  const dispatch = useDispatch();
  const spaceData = useSelector((state) => state.spaces.spaceList.get('data'));
  const { t } = useTranslation();
  const spaceImage = useSelector(state => state.spaces.spaceImg.get('data'));

  useEffect(() => {
    if (isAuth) {
      dispatch(getSpaceImageList());
      dispatch(getUserPreferences());
    }
  }, [ isAuth, spaceData, dispatch ]);

  const toggleDropdown = () => setDropdownOpen((prev) => !prev);

  const [ modalVisible, setModalVisible ] = useState(false);
  const toggleModalVisible = () => setModalVisible(!modalVisible);

  const getImgIcon = (type) => {
    return spaceImage.find(e => e.reference_id === type)?.folder_icon;
  };

  return (
    <StyledNavbar
      color='white'
      className='sticky-top shadow py-3 px-5'
      expand
      $dropdownOpen={dropdownOpen}
    >
      {isAuth &&
        <FavoriteModal visible={modalVisible} toggle={toggleModalVisible} />
      }
      <div className='d-flex justify-content-between align-items-center w-100'>
        <div className='d-flex align-items-center'>
          <NavbarBrand tag={Link} to='/'>
            <InstitutionLogo/>
          </NavbarBrand>
          {isAuth && (
            <div className='ml-5'>
              <Collapse navbar>
                <Nav navbar pills>
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggleDropdown}
                    className='mr-4'
                  >
                    <DropdownToggle nav caret color='secondary'>
                      <div className='d-flex align-items-center'>
                        <span>{t('topbar.my_spaces')}</span>
                        <img src={caretIcon} alt='' className='caret' />
                      </div>
                    </DropdownToggle>
                    <DropMenu modifiers={dropdownMenuModifiers} >
                      {spaceData.map(({ id, name, type }) => (
                        <Fragment key={id}>
                          <DropdownItem data-toggle="tooltip" title={name}>
                            <Link
                              className={cs('dropdown-link space-link', {
                                'active-space-link':
                                  match && match.params.id === id,
                              })}
                              to={`/spaces/${id}/`}
                            >
                              <RowItem>
                                <ColIcon>
                                  <ImgElem
                                    src={getImgIcon(type)}
                                    alt=''
                                  />
                                </ColIcon>
                                <ColText>
                                  <TextValue isActive={id === match?.params.id}>{name}</TextValue>
                                </ColText>
                              </RowItem>
                            </Link>
                          </DropdownItem>
                          <DividerElem className='divider ' />
                        </Fragment>
                      ))}
                      <DropdownItem>
                        <Link to='/new_contract' className='dropdown-link last-elem'>
                          {'+ '}
                          {t('add_space')}
                        </Link>
                      </DropdownItem>
                    </DropMenu>
                  </Dropdown>
                </Nav>
              </Collapse>
            </div>
          )}
        </div>
        <div className='d-flex align-items-center'>
          <TicketsButton auth={isAuth} />
          {isAuth && (
            <div className='d-flex align-items-center'>
              <div className='d-flex align-items-center'>
                <StarButton type="button" onClick={() => toggleModalVisible()}>
                  <StarIcon />
                </StarButton>
                <NotificationsIcon />
              </div>
            </div>
          )}
          <Collapse navbar>
            <Nav navbar pills>
              <Route
                strict
                path='/'
                render={(props) => (
                  // eslint-disable-next-line
                  <Login location={props.location} />
                )}
              />
            </Nav>
          </Collapse>
        </div>
      </div>
    </StyledNavbar>
  );
};

export default TopBar;
