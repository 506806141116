import React from 'react';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';

import Affiliations from './Affiliations';
import AffiliationCategories from './AffiliationCategories';
import AffiliationProcessForm from '../components/AffiliationProcessForm';
import { Container } from '../styles/styled-components/GlobalStyles';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import { AppTitle } from '../utils/componentsLocal';

const AffiliationsPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('page_titles.affiliations', { USERS_UI_NAME: AppTitle() })}</title>
      </Helmet>
      <Container>
        <Switch>
          <Route exact path='/affiliations' render={() => (
            <Affiliations affiliationsNotFoundMessage={t('affiliations_page.empty_list_of_affiliations')}/>
          )}/>
          <Route exact path='/affiliations/new_affiliation' component={AffiliationCategories} />
          <Route exact path='/affiliations/new_affiliation_form/:id' component={AffiliationProcessForm} />
          <Route exact path='/affiliations/refresh_affiliation_form/:id' render={(props) => <AffiliationProcessForm {...props} refresh />} />
        </Switch>
      </Container>
    </>
  );
};

export default compose(
  ErrorBoundary((props) => props.t('affiliations_page.error_boundary'))
)(AffiliationsPage);
