import React from 'react';
import i18n from 'i18next';
import Moment from 'react-moment';
import 'moment/locale/pl';
import DatePicker, { registerLocale } from 'react-datepicker';
import pl from 'date-fns/locale/pl';
import { __env } from '@envloader';

const availableLanguages = [ 'pl', 'en' ];
const defaultLang = 'pl';
registerLocale("pl", pl);

export const checkLanguage = () => {
  return availableLanguages.includes(i18n.language.substring(0,2)) ? i18n.language.substring(0,2) : defaultLang;
};

export const MomentLocale = props => {
  const lang = checkLanguage();
  return (
    <Moment locale={lang} {...props} />
  );
};

export const InputDateLocal = props => {
  const lang = checkLanguage();
  return (
    <DatePicker locale={lang} {...props} />
  );
};

export const AppTitle = () => {
  return __env[`USERS_UI_NAME_${checkLanguage().toUpperCase()}`];
};