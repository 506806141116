/**
 * Usługa -
 * Produkt nabyty przez użytkownika w ramach konkretnej umowy ramowej zgodnie z warunkami
 * dostarczenia produktu zawartymi w umowie ramowej.
 *
 * SERVICE-DATA na ten moment zwraca tylko produkty i oferty, a usług nie.
 * Narazie usługi w danej przestrzeni są zaharkodowane.
 */

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Tooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import SpaceUsersInfo from './statistics/SpaceUsersInfo';
import useCurrentSpace from '../../hooks/useCurrentSpace';
import Loading from '../../../main/components/loading';
import ContractDetails from '../../components/ContractDetails';
import ServicesList from './statistics/ServicesList';
import OffersList from './statistics/OffersList';
import InvitationsList from './statistics/InvitationsList';
import Regimens from './statistics/Regimens';
import useAxiosWithRedux from '../../hooks/useAxiosWithRedux';
import { __env } from '../../../envloader';
import Breadcrumbs  from './Breadcrumbs';
import * as notify from '../../../main/utils/notify';
import { 
  BtnSpace,  
  CustomBody, 
  CustomModal, 
  EditOpen, 
  ErrorMsg, 
  HR, 
  SubTextEdit, 
  TextEditTitle 
} from '../../styles/styled-components/RenameModalStyles';
import { RoundedInput } from '../../styles/styled-components/GlobalStyles';
import { BtnTextBlue, BtnTextGrey } from '../../styles/styled-components/Button';
import axiosInstance from '../../../main/utils/axios/axiosInstance';
import { getSpaceListWithUsers } from '../../actions/spacesActions';
import { UserRole } from '../../constants/roles';
import { canAccess } from '../../utils/rolesUtils';
import CloseWorkspace from './CloseWorkspace';
import useContractState from '../../hooks/useContractState';
import { AppTitle } from '../../utils/componentsLocal';

const SpaceStatistics = ({ match }) => {
  const spaceId = match.params.id;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { roles, space } = useCurrentSpace(match);
  const location = useLocation();
  const userRoles = useSelector((state) => state.spaces.ownRoles.get('data'));

  const rolesInSpace = userRoles?.filter(ele => ele.space_id === spaceId) || [];
  const showUserList = !!rolesInSpace.find(({ role }) => role === "OWNER" || role === "USER_ADMIN" || role === "FINANCIAL_ADMIN");
  const showInvitationList = !!rolesInSpace.find(({ role }) => role === "USER_ADMIN");
  const showContract = !!rolesInSpace.find(({ role }) => role === "FINANCIAL_ADMIN" || role === "OWNER");
  const maxLength = 80;

  const [ tooltipOpen, setTooltipOpen ] = useState(false);
  const [ editModalOpen, setEditModalOpen ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState(false);
  const [ editValue, setEditValue ] = useState("");

  const { data } = useAxiosWithRedux(`${__env.SERVICE_DATA_API_URL}api/public_contract_categories`, 'contractCategories');
  const usersPerSpace = data?.items?.find(item => item.reference_id === space?.type)?.users_per_contract;

  const {
    contractDetails,
    contractDetailsLoading,
  } = useContractState();

  const dataLocations = useMemo(() => ([
    { title: space?.name },
  ]), [ space?.name ]);

  const updateServiceName = () => {
    if (editValue && editValue.trim() !== "") {
      axiosInstance.put(
        `${__env.USERS_DATA_API_URL}api/users-data/spaces/${spaceId}/`,
        {
          name: editValue
        }
      ).then(() => {
        setEditModalOpen(false);
        setErrorMessage(false);
        dispatch(getSpaceListWithUsers());
        notify.success("", t('services_details.name_edit.notify.success'));
      }).catch(() => notify.error("", t('services_details.name_edit.notify.error')));
    }
    else {
      setErrorMessage(true);
    }
  };

  if (!space) {
    return <Loading />;
  }

  if (location.state?.PermissionDenied) {
    notify.error('', `${t('space_permissions.permission_denied')} ${t(`nav_items.${location.state?.view}`)}`);
  }

  return (
    <>
      <div className='d-flex align-items-center'>
        <Breadcrumbs dataLocations={dataLocations} />
        {canAccess([ UserRole.OWNER ], roles) && 
          <div className='d-flex justify-content-between align-items-center w-100'>
            <EditOpen onClick={() => { setEditModalOpen(!editModalOpen); setEditValue(space?.name); }} id="editButton" />
            <Tooltip 
              isOpen={tooltipOpen}
              toggle={() => setTooltipOpen(!tooltipOpen)}
              className="tooltip-normal"
              target="editButton"
            >
              {t('services_details.edit_name')}
            </Tooltip>
            <CloseWorkspace spaceId={spaceId} state={contractDetails?.state} contractDetailsLoading={contractDetailsLoading}/>
          </div>
        }
      </div>
      <Helmet>
        <title>{t("page_titles.spaces.home", { space: space.name, USERS_UI_NAME: AppTitle() })}</title>
      </Helmet>
      {
        editModalOpen &&
        <CustomModal isOpen={editModalOpen} > 
          <CustomBody onClick={e => e.stopPropagation()}>
            <div>
              <TextEditTitle>{t('space_statistics.name_edit')}</TextEditTitle>
              <RoundedInput 
                radius="6px" 
                error={errorMessage}
                style={{ paddingLeft: "12px" }} 
                type="text" 
                value={editValue} 
                onChange={(e) => setEditValue(e.target.value)} 
                maxLength={maxLength} 
                onKeyDown={(e) => {
                  if (e.key === 'Enter'){
                    updateServiceName();
                  }
                }
                }
              />
              { errorMessage && 
                <ErrorMsg>{t('space_statistics.error_message')}</ErrorMsg>
              }
              <SubTextEdit>{t('services_details.name_edit.max_chars')}<b>{maxLength}</b></SubTextEdit>
              <HR/> 
            </div>
            <BtnSpace>
              <BtnTextGrey 
                style={{ margin: 0 }} 
                onClick={() => { setEditModalOpen(false); setErrorMessage(false); }}
              >
                {t('services_details.cancel')}
              </BtnTextGrey>
              <BtnTextBlue style={{ margin: 0 }} primary onClick={() => updateServiceName()}>{t('services_details.change')}</BtnTextBlue>
            </BtnSpace>
          </CustomBody>
        </CustomModal>
      }
      <Card className="p-5 mt-5">
        {
          showContract &&
            <>
              <h5 className="mb-3">{t("contract")}</h5>
              <ContractDetails contractDetails={contractDetails} contractDetailsLoading={contractDetailsLoading} spaceId={spaceId} link={true} />
              <hr />
            </>
        }
        <ServicesList />
        <hr />
        <OffersList />
        <hr />
        {usersPerSpace  !== 'ONE' && (
          <>
            {
              showUserList && (
                <>
                  <SpaceUsersInfo spaceId={spaceId} />
                  <hr />
                </>
              )
            }
            {
              showInvitationList && (
                <>
                  <InvitationsList />
                  <hr />
                </>
              )
            }
          </>
        )
        }
        <Regimens />
      </Card>
    </>
  );
};

SpaceStatistics.propTypes = {
  match: PropTypes.object.isRequired, // HOC
};

export default SpaceStatistics;
