import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { Modal, UncontrolledTooltip } from 'reactstrap';
import { useDispatch } from 'react-redux';

import { __env } from './../../envloader/index';
import axiosInstance from '../../main/utils/axios/axiosInstance';
import { getServices } from '../actions/servicesActions';
import { DeleteIcon } from '../../assets/svg/services/icons';
import { CustomModalBody, CustomModalFooter, CustomModalHeader } from '../styles/styled-components/CloseService';
import { HiglightedText } from '../styles/styled-components/UsersActionModal';
import { BtnFilledRed, BtnGrey } from '../styles/styled-components/Button';
import errorSvg from '../../assets/svg/common/red-circle-warning.svg';

const CloseService = ({ displayName, id, spaceId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [ isOpen, setIsOpen ] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = () => {
    axiosInstance.post(`${__env.USERS_DATA_API_URL}api/users-data/services/${id}/hide/`);
    dispatch(getServices(spaceId));
  };

  return (
    <div className="d-flex justify-content-end">
      <button id={`delete_service_${id}`} style={{ all: 'unset', cursor: 'pointer' }} onClick={toggle}>
        <DeleteIcon />
      </button>
      <UncontrolledTooltip
        placement="bottom"
        target={`delete_service_${id}`}
      >
        {t('delete_service')}
      </UncontrolledTooltip>
      {isOpen &&
        <Modal style={{ maxWidth: "452px" }} centered isOpen={isOpen} toggle={toggle}>
          <CustomModalHeader>
            <div>
              <img src={errorSvg} alt='error icon'/>
            </div>
            <p>
              <Trans i18nKey="services.modal.delete_service">
                Are you sure you want to<br /> 
                <HiglightedText red>delete the service</HiglightedText>?
              </Trans>
            </p>
          </CustomModalHeader>
          <CustomModalBody>   
            <p>
              <Trans i18nKey="modal.delete_service_info">
                Service: <strong>{displayName}</strong>.<br/>This action will be irreversible.
              </Trans>
            </p>  
          </CustomModalBody>
          <CustomModalFooter>
            <BtnGrey style={{ flex: 1, justifyContent: "center" }} onClick={toggle}>{t('cancel')}</BtnGrey>
            <BtnFilledRed style={{ flex: 1, justifyContent: "center" }} onClick={handleClick}>{t('delete_service')}</BtnFilledRed>
          </CustomModalFooter>
        </Modal>
      }
    </div>
  );
};

CloseService.propTypes = {
  displayName: PropTypes.string,
  id: PropTypes.string,
  spaceId: PropTypes.string
};

export default CloseService;