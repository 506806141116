import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
`;

export const CustomCard = styled.button`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 240px;
  height: 200px;
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.colors.text.greyDivider};
  border-radius: 20px;
  cursor: pointer;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  -webkit-backface-visibility: hidden;

  &:hover {
    will-change: transform;
    border: 1px solid ${({ theme }) => theme.colors.secondaryDisabled};
    -webkit-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -o-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02);
  }

  &:active {
    border: 1px solid ${({ theme }) => theme.colors.text.blueMost};
  }
`;

export const CustomCardTitle = styled.div`
  color: ${({ theme }) => theme.colors.text.black};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  width: 100%;

  text-align: center;
  display: -webkit-box;
  max-height: 48px;  
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;