import React from 'react';
import PropTypes from 'prop-types';

import { CustomTooltipContainer } from '../../styles/styled-components/SettlementDetails';
import { formatBasedOnValue } from '../../utils/formatBasedOnValue';

const CustomTooltipForBar = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <CustomTooltipContainer>
        <span>{payload[0]?.payload?.name}</span>
        <span>{`${formatBasedOnValue(payload[0]?.value)} ${payload[0]?.payload?.unit}`}</span>
      </CustomTooltipContainer>
    );
  }

  return null;
};

export default CustomTooltipForBar;

CustomTooltipForBar.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.object
};