import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, NavItem, NavLink as ReactstrapNavLink  } from 'reactstrap';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Switch, Route, Redirect, useLocation, NavLink as RouterNavLink } from 'react-router-dom';

import { CardWarning, StyledNav } from '../../styles/styled-components/SpaceInvitations';
import PrivateInvitations from './private/PrivateInvitations';
import PublicInvitations from './public/PublicInvitations';
import useCurrentSpace from '../../hooks/useCurrentSpace';
import { Invitation } from '../../constants/invitations';
import redCircleWarningIcon from '../../../assets/svg/common/red-circle-warning.svg';
import Breadcrumbs  from '../spaces/Breadcrumbs';
import { AppTitle } from '../../utils/componentsLocal';

const SpaceInvitations = ({ match }) => {
  const { t } = useTranslation();
  const { space } = useCurrentSpace(match);
  const location = useLocation();

  const [ isLinkWithExpiredDate, setIsLinkWithExpiredDate ] = useState(false);
  
  const currentTab = useMemo(() => 
    location.pathname.includes('public') ? Invitation.PUBLIC : Invitation.PRIVATE,
  [ location.pathname ]);
  
  const dataLocation = [
    { title: space?.name, location: `/spaces/${space?.id}` },
    { title: t('nav_items.invitations') }
  ];
 
  return (
    <>
      <Breadcrumbs  dataLocations={dataLocation} />
      <Helmet className='mt-5'>
        <title>{space && t('page_titles.spaces.invitations', { space: space.name, USERS_UI_NAME: AppTitle() })}</title>
      </Helmet>
      <section className='mt-5'>
        <p>
          {t('space_invitations.section_title')}
        </p>
        {
          isLinkWithExpiredDate && 
            <CardWarning className='p-4 my-4'>
              <div className='d-flex'>
                <img className="mr-3" height="42px" src={redCircleWarningIcon} alt=""/>
                <span><Trans i18nKey="space_invitations.error_link_expired" /></span>
              </div>
            </CardWarning>
        }
        <Card className='p-4'>
          <CardBody className='p-2'>
            <StyledNav tabs>
              {Object.entries(Invitation).map(([ key, value ]) => (
                <NavItem>
                  <ReactstrapNavLink 
                    tag={RouterNavLink}
                    to={`${value}`}
                    active={currentTab === key}
                  >
                    {t(`space_invitations.${value}_invitations`)}
                  </ReactstrapNavLink>
                </NavItem>
              ))}
            </StyledNav>
            <Switch>
              <Route
                path={`/spaces/${match.params.id}/invitations/private`}
                render={() => <PrivateInvitations spaceId={match.params.id}/>}
              />
              <Route
                path={`/spaces/${match.params.id}/invitations/public`}
                render={() => <PublicInvitations spaceId={match.params.id} setIsLinkWithExpiredDate={setIsLinkWithExpiredDate}/>}
              />
              <Redirect
                exact
                from={`/spaces/:id/invitations`}
                to={`/spaces/:id/invitations/private`}
              />
            </Switch>
          </CardBody>
        </Card>
      </section>
    </>
  );
};

SpaceInvitations.propTypes = {
  match: PropTypes.object.isRequired
};

export default SpaceInvitations;
