import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownItem } from 'reactstrap';

import axiosInstance from '../../main/utils/axios/axiosInstance';
import * as notify from '../../main/utils/notify';
import { __env } from '../../envloader';
import { createActionsTable } from '../utils/serviceDetails';

import { 
  TextBold,
  TextGrey,
  StatusPoint,
  StatusContainer,
  StatusChooser,
  ChevronDownIcon,
  ChooseStatusMenu,
  StatusMenuOption,
  StatusMenuTitle,
  StatusMenuTitleBlue,
} from '../styles/styled-components/ServiceDetails';
import { 
  CustomModal,
  CustomBody,
  BtnSpace
} from '../styles/styled-components/RenameModalStyles';
import FavoriteButton from './FavoriteButton';
import { useDispatch } from 'react-redux';
import { getServices } from '../actions/servicesActions';
import backendTranslation from '../utils/backendTranslation';
import { BtnTextBlue, BtnTextGrey } from '../styles/styled-components/Button';

const ServiceDetailsStatusContainer = ({ locations, match, state, stateId, getServiceStateChange, setIsStatusChanging, spaceId, isStatusChanging }) => {

  const { t } = useTranslation();

  const [ statusDropdownOpen, setStatusDropdownOpen ] = useState(false);
  const [ chosenAction, setChosenAction ] = useState();
  const [ chosenActionTrans, setChosenActionTrans ] = useState("");
  const [ statusActions, setStatusActions ] = useState([]);
  const [ statusModalOpen, setStatusModalOpen ] = useState(false);
  const [ idContractModalOpen, setIdContractModalOpen ] = useState(0);
  const dispatch = useDispatch();

  const chooseAction = (action, trans) => {
    setChosenAction(action);
    setChosenActionTrans(trans);
    setStatusModalOpen(true);
    setStatusDropdownOpen(false);
  };

  const cancelStatusAction = () => {
    setStatusModalOpen(false);
    setChosenAction(undefined);
    setChosenActionTrans("");
  };

  const changeStatus = () => {
    setStatusModalOpen(false);
    setIsStatusChanging(true);
    if (stateId && state) {
      axiosInstance.post(`${__env.BPM_API_URL}services/change_state`, {
        service_id: stateId,
        from_state: state,
        to_state: statusActions[chosenAction]?.value
      })
        .then(() => {
          dispatch(getServices((spaceId)));
          getServiceStateChange();
          notify.success("", t('services_details.status_change.begin'));
        })
        .catch(() => notify.error("", t('services_details.status_change.error')));
    }
  };

  useEffect(() => {
    if (state) setStatusActions(createActionsTable(state));
  }, [ state ]);

  const toggleStatusDropdownOpen = () => setStatusDropdownOpen(!statusDropdownOpen);

  return (
    <div className="d-flex flex-column w-25">
      <StatusContainer>
        <div className="d-flex">
          <div className="mr-4">
            <TextGrey>{t('services_details.status')}</TextGrey>
            <div className="d-flex align-items-center">
              <StatusPoint />
              <TextBold>{t(`service_status.${state.toLowerCase()}`)}</TextBold>
            </div>
          </div>
          <div className="d-flex align-items-start">
            <FavoriteButton 
              spaceId={match.params.id} 
              id={match.params.service_id} 
              type="services" 
            />
          </div>
        </div>
        {
          statusActions.length > 0 && (
            <div className="d-flex flex-column">
              <TextGrey className="mb-1 mt-2">{t('services_details.actions')}</TextGrey>
              <Dropdown isOpen={statusDropdownOpen} toggle={toggleStatusDropdownOpen} disabled={isStatusChanging} >
                <StatusChooser tag="button" disabled={isStatusChanging}>
                  <span>{t('services_details.choose')}...</span>
                  <div disabled={isStatusChanging}>
                    <ChevronDownIcon size="1rem"/>
                  </div>
                </StatusChooser>
                <ChooseStatusMenu>
                  <DropdownItem header className="p-0 mb-2">{t('services_details.change_action')}</DropdownItem>
                  <div className="d-flex flex-column align-items-start">
                    {statusActions.map((option, idx) => (
                      <StatusMenuOption onClick={() => { chooseAction(idx, option.trans); }} >
                        {option.label}
                      </StatusMenuOption>
                    ))}
                  </div>
                </ChooseStatusMenu>
              </Dropdown>
            </div>
          )
        }
        {
          statusModalOpen &&
          <CustomModal isOpen={statusModalOpen} style={{ width: "380px", minWidth: "380px" }}>
            <CustomBody onClick={e => e.stopPropagation()} style={{ minHeight: "180px" }}>
              <StatusMenuTitle>
                {t("services_details.are_you_sure.main")}
                <br />
                <StatusMenuTitleBlue> {t(`services_details.are_you_sure.actions.${chosenActionTrans}`)} </StatusMenuTitleBlue>
                {t("services_details.are_you_sure.service")}
              </StatusMenuTitle>
              <BtnSpace style={{ justifyContent: "space-between" }}>
                <BtnTextGrey style={{ margin: 0 }} onClick={cancelStatusAction}>{t('services_details.cancel')}</BtnTextGrey>
                <BtnTextBlue style={{ margin: 0 }} onClick={changeStatus}>{t('yes')}</BtnTextBlue>
              </BtnSpace>
            </CustomBody>
          </CustomModal>
        }
        {
          Boolean(idContractModalOpen) &&
            <CustomModal isOpen={idContractModalOpen} style={{ width: "380px" }}>
              <CustomBody onClick={e => e.stopPropagation()} style={{ minHeight: "180px", padding: "20px 24px" }}>
                {backendTranslation(locations?.items[idContractModalOpen]?.description)}
                <BtnSpace>
                  <BtnTextGrey style={{ margin: 0 }} onClick={() => setIdContractModalOpen(0)}>{t('reports.close')}</BtnTextGrey>
                </BtnSpace>
              </CustomBody>
            </CustomModal>
        }
      </StatusContainer>
    </div>
  );
};

ServiceDetailsStatusContainer.propTypes = {
  match: PropTypes.object.isRequired,
  state: PropTypes.string,
  stateId: PropTypes.string,
  serviceUrl: PropTypes.string,
  isStatusChanging: PropTypes.bool,
  getServiceStateChange: PropTypes.func,
  setIsStatusChanging: PropTypes.func,
  spaceId: PropTypes.string,
  locations: PropTypes.object
};

export default ServiceDetailsStatusContainer;