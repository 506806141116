import React, { useCallback , useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell, CartesianGrid } from 'recharts';

import { CustomDropdown, CustomDropdownItem, CustomDropdownMenu, CustomDropdownToggle, UsageTitle } from '../../../styles/styled-components/SettlementDetails';
import { AngleDown } from '../../../styles/styled-components/ServiceUsageBar';
import Loading from '../../../../main/components/loading';
import ServiceUsageBar from '../../ServiceUsageBar';
import { useTranslation } from 'react-i18next';
import backendTranslation from '../../../utils/backendTranslation';
import CustomTooltipForBar from '../CustomTooltipForBar';
import ChevronIcon from '../../../../assets/svg/settlements/chevron.svg';

const UsageDatails = ({ loadingResourcesUsage, resourcesUsage }) => {
  const { t } = useTranslation();

  const [ activeIndex, setActiveIndex ] = useState(-1);
  const [ groupedData, setGroupedData ] = useState({});
  const [ selectedIndexes, setSelectedIndexes ] = useState({});
  const [ isOpenDropdown, setIsOpenDropdown ] = useState({});
  const [ isOpen, setIsOpen ] = useState(true);

  const handleSelect = (idx, date) => {
    setSelectedIndexes(prev => ({ ...prev, [idx]: date }));
  };

  const toggleDropdown = (idx) => {
    setIsOpenDropdown(prev => ({ ...prev, [idx]: !prev[idx] }));
  };

  const getDay = (dateStr) => {
    const date = new Date(dateStr);

    return date.getDate();
  };

  const getMonthAbbreviation = (dateStr, long) => {
    const date = new Date(dateStr);
    const monthFormat = long ? 'long' : 'short';

    let monthAbbreviationPL = new Intl.DateTimeFormat('pl-PL', { month: monthFormat }).format(date);
    let monthAbbreviationEN = new Intl.DateTimeFormat('en-EN', { month: monthFormat }).format(date);
  
    if (long) {
      monthAbbreviationPL = monthAbbreviationPL.charAt(0).toUpperCase() + monthAbbreviationPL.slice(1).toLowerCase();
      monthAbbreviationEN = monthAbbreviationEN.charAt(0).toUpperCase() + monthAbbreviationEN.slice(1).toLowerCase();
    } 
    else {
      monthAbbreviationPL = monthAbbreviationPL.toUpperCase();
      monthAbbreviationEN = monthAbbreviationEN.toUpperCase();
    }

    return { pl: monthAbbreviationPL, en: monthAbbreviationEN };
  };

  const filteredData = useCallback((resourcesUsage, type, unit) => {
    const dateFrom = new Date(resourcesUsage?.date_from);
    const dateTo = new Date(resourcesUsage?.date_to);

    const differenceInDays = ((dateTo.getTime() - dateFrom.getTime()) / (1000 * 3600 * 24)) > 31;

    return resourcesUsage?.resource_usage_data?.subperiods?.map(item => {
      const resource = item?.resources?.find(resource => resource?.name === type);

      if (!resource || resource.value === undefined) {
        return null;
      }

      return {
        name: item?.name,
        value: resource?.value,
        unit: backendTranslation(unit),
        convertDate: differenceInDays ? backendTranslation(getMonthAbbreviation(item?.name)) : getDay(item?.name)
      };
    }).filter(item => item.value !== undefined);
  }, []);

  const groupByMonthOrYear = (data) => {
    if (data.length === 0) return {};
  
    let keyExtractor;

    if (data.length > 1) {
      const date1 = new Date(data[0].name);
      const date2 = new Date(data[1].name);
      const dayDiff = (date2 - date1) / (1000 * 3600 * 24);

      if (Math.abs(dayDiff) > 1) {
        keyExtractor = item => (new Date(item.name)).getFullYear().toString();
      } 
      else {
        keyExtractor = item => (new Date(item.name)).getFullYear() + '-' + ((new Date(item.name)).getMonth() + 1).toString().padStart(2, '0');
      }
    } 
    else {
      keyExtractor = item => (new Date(item.name)).getFullYear() + '-' + ((new Date(item.name)).getMonth() + 1).toString().padStart(2, '0');
    }

    const groups = {};
  
    data.forEach(item => {
      const key = keyExtractor(item);
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(item);
    });

    return groups;
  };

  useEffect(() => {
    const data = resourcesUsage?.resource_usage_data?.resources?.reduce((acc, resource, idx) => {
      const filtered = filteredData(resourcesUsage, resource.name, resource.unit);
      const grouped = groupByMonthOrYear(filtered);
      acc[idx] = grouped;
      return acc;
    }, {});
  
    setGroupedData(data);
  }, [ filteredData, resourcesUsage ]);

  if (loadingResourcesUsage) {
    return <Loading />;
  };

  if (!resourcesUsage?.resource_usage_data?.resources?.length) {
    return null;
  };

  return (
    <>
      <UsageTitle onClick={() => setIsOpen(!isOpen)}>
        {t('settlements_details.usage_details')}
        <AngleDown className="ml-2" reverse={isOpen ? 1 : 0} />
      </UsageTitle>
      <Collapse isOpen={isOpen}>
        {resourcesUsage?.resource_usage_data?.resources?.map((resource, idx) => (
          <>
            <ServiceUsageBar key={resource.name} currentData={resourcesUsage} type={resource.name} settlement eleNum={idx} />
            {groupedData[idx] && Object.keys(groupedData[idx]).length > 0 &&
              <>
                { Object.keys(groupedData[idx])?.length > 1 &&
                  <CustomDropdown isOpen={isOpenDropdown[idx]} toggle={() => toggleDropdown(idx)}>
                    <CustomDropdownToggle isOpen={isOpenDropdown[idx]}>
                      <snap>{/^\d{4}$/.test(Object.keys(groupedData[idx])?.[0]) ? t('settlements_details.years') : t('settlements_details.months')}</snap>
                      <img src={ChevronIcon} alt='chevron-icon'/>
                    </CustomDropdownToggle>
                    <CustomDropdownMenu>
                      {Object.keys(groupedData[idx]).map((date, index) => (
                        <CustomDropdownItem 
                          key={index} 
                          selected={selectedIndexes?.[idx] === date || (!selectedIndexes?.[idx] && index === 0)} 
                          onClick={() => handleSelect(idx, date)}
                        >
                          {/^\d{4}$/.test(date) ? date : backendTranslation(getMonthAbbreviation(date, 'long'))}
                        </CustomDropdownItem>
                      ))}
                    </CustomDropdownMenu>
                  </CustomDropdown>
                }
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart 
                    data={groupedData[idx][selectedIndexes[idx]] || Object.values(groupedData[idx])[0]} 
                    margin={{ top: 30, right: 30, left: 0, bottom: 5 }}
                  >
                    <CartesianGrid stroke="#f5f5f5" strokeDasharray="none" vertical={false}/>
                    <XAxis dataKey="convertDate" axisLine={false} tickLine={false}/>
                    <YAxis axisLine={false} tickLine={false}/>
                    <Tooltip content={<CustomTooltipForBar />}/>
                    <Bar dataKey="value" fill="none" onMouseEnter={(_, id) => setActiveIndex(`${resource.name}-${id}`)} onMouseLeave={() => setActiveIndex(-1)}>
                      {
                        resourcesUsage?.resource_usage_data?.subperiods?.map((_, index) => (
                          <Cell key={`cell-${index}`} fill={`${resource.name}-${index}` === activeIndex ? '#0096F6' : '#EDF5FF'} stroke={undefined}/>
                        ))
                      }
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </> 
            }
          </>
        ))}
      </Collapse>
    </>
  );
};

export default UsageDatails;

UsageDatails.propTypes = {
  loadingResourcesUsage: PropTypes.bool,
  resources: PropTypes.array,
  resourcesUsage: PropTypes.object
};