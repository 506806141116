import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { Modal } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import { BtnFilledRed, BtnGrey, BtnRed } from '../../styles/styled-components/Button';
import Loading from '../../../main/components/loading';
import axiosInstance from '../../../main/utils/axios/axiosInstance';
import { __env } from '../../../envloader';
import errorSvg from '../../../assets/svg/common/red-circle-warning.svg';
import { HiglightedText } from './../../styles/styled-components/UsersActionModal';
import { CustomModalBody, CustomModalHeader, CustomModalFooter } from './../../styles/styled-components/CloseWorkspace.js';
import backendTranslation from '../../utils/backendTranslation.js';

const CloseWorkspace = ({ contractDetailsLoading, spaceId, state }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [ isOpen, setIsOepn ] = useState(false);
  const [ errorMsg, setErrorMsg ] = useState(null);
  const [ isDeleting, setIsDeleting ] = useState(false);

  const toggle = () => {
    !isDeleting && setIsOepn(!isOpen);
  };

  const handleClick = async () => {
    try {
      setIsDeleting(true);
      await axiosInstance.delete(`${__env.BPM_API_URL}offers/delete_space/${spaceId}`, { __silentFor: [ { status: 409 } ] });
      history.push('/');
    }
    catch (error) {
      if (error.response?.status === 409) {
        setIsDeleting(false);
        setIsOepn(true);
        setErrorMsg(error.response?.data?.message);
      }
      else {
        toggle();
      }
    }
  };

  if (contractDetailsLoading) {
    return <Loading />;
  }
  
  if (![ "ACTIVE", "EXPIRED" ].includes(state)) {
    return null;
  }

  return (
    <>
      <BtnRed transparent onClick={toggle}>{t("delete_space")}</BtnRed>
      {isOpen &&
        <Modal style={{ maxWidth: "452px" }} centered isOpen={isOpen} toggle={toggle}>
          <CustomModalHeader isDeleting={isDeleting}>
            {!isDeleting &&
              <div>
                <img src={errorSvg} alt='error icon'/>
              </div>
            }
            {isDeleting ? (
              <>
                <Loading />
                <p>{t('deleting_space_in_progress')}</p>
              </>
            )
              : errorMsg 
                ? (
                  <p>{t('error_deleting_space')}</p>
                )
                : (
                  <p>
                    <Trans i18nKey="modal.delete_space">
                      Are you sure you want to<br /> 
                      <HiglightedText red>delete the space</HiglightedText>?
                    </Trans>
                  </p>
                )
            }
          </CustomModalHeader>
          {!isDeleting &&       
            <CustomModalBody>
              {errorMsg 
                ? (
                  <p>{backendTranslation(errorMsg)}</p>
                ) 
                : (
                  <p>
                    <Trans i18nKey="modal.delete_space_com">
                      Pamiętaj, że usuwając przestrzeń <strong>utracisz dostęp do danych</strong>. Czy na pewno chcesz kontynuować?
                    </Trans>
                  </p>
                )}
            </CustomModalBody>
          }
          {!isDeleting &&
            <CustomModalFooter>
              <BtnGrey style={{ flex: 1, maxWidth: "186px" }} onClick={toggle}>{t('cancel')}</BtnGrey>
              {!errorMsg && (
                <BtnFilledRed style={{ flex: 1 }} onClick={handleClick}>{t(`delete_space`)}</BtnFilledRed>
              )}
            </CustomModalFooter>
          }
        </Modal>
      }
    </>
  );
};

CloseWorkspace.propTypes = {
  contractDetailsLoading: PropTypes.bool,
  spaceId: PropTypes.string,
  state: PropTypes.string
};

export default CloseWorkspace;