import styled from 'styled-components';
import { ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

export const CustomModalHeader = styled(ModalHeader)`
  display: flex;
  justify-content: center;
  padding: 24px 32px 16px 32px;
  border-bottom: none;

  .modal-title {
    display: flex;
    flex-direction: column;

    p {
      font-size: ${({ theme }) => theme.fontSizes.lg};
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      line-height: 24px;
      text-align: center;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    div > img {
      object-fit: contain;
      margin-bottom: 24px;
    }
  }
`;

export const CustomModalBody = styled(ModalBody)`
  padding: 0 32px;

  p {
    text-align: center;
    margin: 0;

    font-size: ${({ theme }) => theme.fontSizes.sm};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    line-height: 20px;
  }
`;

export const CustomModalFooter = styled(ModalFooter)`
  display: flex;
  justify-content: center;
  padding: 32px;
  gap: 16px;
  border-top: none;
`;