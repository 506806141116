import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ModalHeader, ModalBody, UncontrolledTooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { __env } from '../../../envloader';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import * as notify from '../../../main/utils/notify';
import axiosInstance from '../../../main/utils/axios/axiosInstance';
import backendTranslation from '../../utils/backendTranslation';
import FavoriteButton from '../../components/FavoriteButton';
import { StarIcon } from '../../styles/styled-components/GlobalStyles';
import { ReactComponent as SearchIcon } from '../../../assets/svg/common/search.svg';
import NoFacoriteIcon from '../../../assets/svg/favorites/no_favorites.svg';
import Theme from '../../styles/styled-components/Theme';
import { 
  ModalFavorite,
  FMTitle,
  FMTitleText,
  FMTitleNumber,
  FMSearchLabel,
  FMInputContainer,
  FMInputButton,
  FMLastAddedTitle,
  FMBody,
  FMList,
  FMListElement,
  FMListElementSubTitle,
  FMListElemenetTitle,
  FMListElementLink,
  FMNoFavoritesImage,
  FMNoFavoritesTitle,
  FMNoFavoritesSubTitle
} from '../../styles/styled-components/FavoriteModal';
import { RoundedInput } from '../../styles/styled-components/GlobalStyles';
import Loading from '../../../main/components/loading';
const FavoriteSpaces = ({ visible, toggle }) => {

  const { t } = useTranslation();
  const history = useHistory();
  const selectsFavoriteData = state => ({
    favoritesData: state.userPreferences.get('favorites'),
    isLoadingFavoritesData: state.services.servicesList.get('isLoading')
  });
  const selectsAllSpaces = state => ({
    allSpaces: state.spaces.spaceList.get('data'),
    isLoadingAllSpaces: state.spaces.spaceList.get('isLoading')
  });
  const { favoritesData, isLoadingFavoritesData } = useSelector(selectsFavoriteData);
  const { allSpaces, isLoadingAllSpaces } = useSelector(selectsAllSpaces);

  const [ searchInput, setSearchInput ] = useState("");
  const [ data, setData ] = useState([]);
  const [ showData, setShowData ] = useState([]);
  const [ isCursor, setIsCursor ] = useState(null);
  const [ isTextOverflow, setIsTextOverflow ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);

  const checkTextOverflow = (element) => {
    const isEllipsis = element.offsetWidth < element.scrollWidth;
    setIsTextOverflow(isEllipsis);
    setIsCursor(isEllipsis);
  };

  useEffect(() => {
    const getData = async (mainData, type) => {
      let result = [];
      if (type === "spaces") {
        const tempData = mainData.map(favEle => allSpaces.find(space => space.id === favEle.id));
        result = tempData.map(ele => ({
          title: ele.name,
          spaceId: ele.id,
          id: ele.id,
          type, 
          link: `/${type}/${ele.id}`
        }));
      } 
      else  {
        const spacesData = mainData.map(favEle => allSpaces.find(space => space.id === favEle.space));
        let link = "";
        for (let i = 0; i < mainData.length; i++) {
          if (type === "services") link = `${__env.USERS_DATA_API_URL}api/users-data/services/${mainData[i].id}`;
          if (type === "offers") link = `${__env.SERVICE_DATA_API_URL}api/user_offers/${mainData[i].id}`;
          await axiosInstance.get(link)
            .then(response => {
              const responseData = response.data;
              let tempObject = {};
              if (type === "services") tempObject = {
                title: responseData.name,
                subTitle: spacesData[i].name,
                type,
                link: `/spaces/${mainData[i].space}/services/${mainData[i].id}` 
              };
              if (type === "offers") tempObject = {
                title: backendTranslation(responseData.item.offer__name),
                subTitle: spacesData[i].name,
                type,
                link: `/spaces/${mainData[i].space}/offers/${mainData[i].id}` 
              };
              tempObject.spaceId = mainData[i].space;
              tempObject.id = mainData[i].id;
              result.push(tempObject);
            })
            .catch(() => notify.error("", "error"));
        }
      }
      return result;
    };

    if (visible && !isLoadingFavoritesData && !isLoadingAllSpaces) {
      let tempData = [];
      async function fetchData() {
        if (favoritesData.spaces) tempData = await getData(favoritesData.spaces, "spaces");
        if (favoritesData.services) tempData = [ ...tempData, ...await getData(favoritesData.services, "services") ];
        if (favoritesData.offers) tempData = [ ...tempData, ...await getData(favoritesData.offers, "offers") ];
        setData(tempData);
        setShowData(tempData);
        await Promise.all([
          setIsLoading(false)
        ]);
      }
      fetchData();
    }
  }, [ visible, favoritesData, allSpaces, isLoadingFavoritesData, isLoadingAllSpaces ]);

  // Search function
  useEffect(() => {
    let tempData = data.filter((ele) =>
      ele.title?.toLowerCase().includes(searchInput.toLowerCase())
    );
    setShowData(tempData);
  }, [ data, searchInput ]);

  // Redirect function
  const favRedirect = link => {
    toggle();
    setSearchInput("");
    history.push(link);
  };

  return (
    <ModalFavorite isOpen={visible} toggle={toggle}>
      <Theme>
        <ModalHeader toggle={toggle}>
          <FMTitle>
            <StarIcon /> <FMTitleText>{t('favorite.title')} (<FMTitleNumber>{showData.length}</FMTitleNumber>)</FMTitleText>
          </FMTitle>
        </ModalHeader>
        <ModalBody>
          <FMSearchLabel>{t('favorite.search')}</FMSearchLabel>
          <FMInputContainer>
            <RoundedInput
              type="text"
              name="favorite"
              radius="24px"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder={t('favorite.searchbar')}
            />
            <FMInputButton><SearchIcon /></FMInputButton>
          </FMInputContainer>
          {
            showData.length > 0 ? (
              <FMBody>
                <FMLastAddedTitle>{t('favorite.last_added')}</FMLastAddedTitle>
                <FMList>
                  {showData.map((ele, index) => (
                    <FMListElement
                      key={`favorite-modal-${uuidv4()}`}
                    >
                      <div className="d-flex w-100">
                        <FavoriteButton spaceId={ele.spaceId} id={ele.id} type={ele.type} alwaysActive />
                        <div className="d-flex flex-column justify-content-center">
                          <FMListElemenetTitle
                            isCursor={isCursor}
                            id={`title-favorite-${index}`}
                            onMouseEnter={(event) => {
                              checkTextOverflow(event.target);
                          
                            }}
                          >
                            {ele.title}
                          </FMListElemenetTitle>
                          {isTextOverflow && 
                            <UncontrolledTooltip
                              placement="top"
                              target={`title-favorite-${index}`}
                            >
                              {ele.title}
                            </UncontrolledTooltip>
                          }
                          { ele.subTitle && <FMListElementSubTitle>{t('favorite.space')} {ele.subTitle}</FMListElementSubTitle> }
                        </div>
                      </div>
                      <FMListElementLink onClick={() => favRedirect(ele.link)} >{t('favorite.go')} &gt;</FMListElementLink>
                    </FMListElement>
                  ))}
                </FMList>
              </FMBody>
            ) :
              (isLoading || isLoadingFavoritesData || isLoadingAllSpaces
                ? ( 
                  <Loading/>
                ) :
                (
                  <div className="d-flex flex-column justify-content-center align-items-center w-100">
                    <FMNoFavoritesImage src={NoFacoriteIcon} />
                    <FMNoFavoritesTitle>{t('favorite.no_favorites_title')}</FMNoFavoritesTitle>
                    <FMNoFavoritesSubTitle>
                      <Trans i18nKey="favorite.no_favorites_subtitle">Dodaj do Ulubionych<br /> co najważniejsze</Trans>
                    </FMNoFavoritesSubTitle>
                  </div>
                )
              )
          }
        </ModalBody>
      </Theme>
    </ModalFavorite>
  );
};

FavoriteSpaces.propTypes = {
  visible: PropTypes.bool,
  toggle: PropTypes.func,
};

export default FavoriteSpaces;
