import styled from 'styled-components';
import { Col, DropdownMenu, Navbar, Row } from 'reactstrap';

export const DropMenu = styled(DropdownMenu)`
  max-width: 228px !important;
  overflow-y: auto;
  scrollbar-width: 5px;
  border-right: solid 6px ${({ theme }) => theme.colors.white};
  border-top: solid 16px ${({ theme }) => theme.colors.white};
  border-bottom: solid 16px ${({ theme }) => theme.colors.white};
  padding-right: 10px;

  ::-webkit-scrollbar {
    width: 5px;
  }
`;

export const StyledNavbar = styled(Navbar)`
  .dropdown-toggle {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ theme }) => theme.colors.secondary};

    &:after {
      display: none;
    }
  }

  .dropdown-item {
    padding: 0;
    &:hover, &:active {
      background-color: transparent;
    }
  }

  .dropdown-menu {
    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 0;
    transition: opacity .3s ease;
    opacity: ${(props) => props.$dropdownOpen ? 1 : 0};
    box-shadow: 0px 2px 8px rgba(127, 135, 141, 0.08), 0px 20px 32px rgba(72, 79, 86, 0.1);
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 375px;
    min-width: 230px;
    max-width: 300px;
  }

  .nav-link.active, .nav-pills .show > .nav-link {
    background-color: ${({ theme }) => theme.colors.background.white};
    color: ${({ theme }) => theme.colors.secondary};
  }

  .dropdown-link {
    display: block;
    text-align: center;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: ${({ theme }) => theme.colors.secondary};
    padding-left: 0;
    padding-right: 0%;
    &:hover {
      text-decoration: none;
    }
  }

  .dropdown-link.last-elem {
    margin: 16px 0;
    padding-left: 39px;
    text-align: left;
  };

  .divider {
    height: 1px;
    margin: 5px auto;
    background-color: ${({ theme }) => theme.colors.text.grey4};
  }

  .space-link {
    color: ${({ theme }) => theme.colors.text.grey1};
  }

  .active-space-link {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }

  .alert-icon {
    margin-bottom: -8px;
  }

  .caret {
    margin-left: 5px;
    transition: transform .5s ease;
    transform: ${(props) => props.$dropdownOpen ? 'rotate(180deg)' : 'rotate(0)'};
  }
`;

export const RowItem = styled(Row)` 
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;
    flex-wrap: nowrap;
    max-height: 54px;
`;

export const ColIcon = styled(Col)`
    max-width: 24px;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ImgElem = styled.img`
    padding: 15px 0;
    max-width: 24px;
`;

export const ColText = styled(Col)`
    width: 100%;
    text-align: left;
    margin-left: 12px;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
`;

export const TextValue = styled.span`
  ${({ theme, isActive }) => isActive 
    ? `
      color: ${theme.colors.text.black};
      font-weight: ${theme.fontWeights.bold};
    `
    : `
      color: ${theme.colors.text.greyHover};
      font-weight: ${theme.fontWeights.normal};
      &:hover {
        font-weight: ${theme.fontWeights.bold};
        color: ${theme.colors.text.lessDarkGrey};
      }
      &:active {
        // font-weight: ${theme.fontWeights.bold};
        color: ${theme.colors.text.black};
      }
    `};
  max-width: 150px;
  max-height: 21px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 15px 0;
`;

export const DividerElem = styled.div`
    margin: 0 16px !important;
`;