import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PaidIcon } from '../../../assets/svg/spaces/paid.svg';
import { ReactComponent as RejectedIcon } from '../../../assets/svg/spaces/rejected.svg';
import { ReactComponent as CheckingIcon } from '../../../assets/svg/spaces/checking.svg';

export const billingStatuses = {
  ACTIVE: "ACTIVE",
  IN_REVIEW: "IN_REVIEW",
  UPDATED: "UPDATED",
  ACCEPTED: "ACCEPTED",
  DENIED: "DENIED",
};

export const settlementStates = {
  PAID: "PAID",
  SETTLED: "SETTLED",
  PREPARED: "PREPARED",
  DONE: "DONE",
};

const Badge = ({ billingStatus, state }) => {
  const { t } = useTranslation();

  const statusKey = billingStatus || state;
  const statusMapping = {
    [settlementStates.PAID]: { icon: <PaidIcon className="mr-2" />, text: t("reports.status.settled") },
    [settlementStates.SETTLED]: { icon: <PaidIcon className="mr-2" />, text: t("reports.status.settled") },
    [settlementStates.PREPARED]: { icon: <PaidIcon className="mr-2" />, text: t("reports.status.settled") },
    [settlementStates.DONE]: { icon: <PaidIcon className="mr-2" />, text: t("reports.status.settled") },
    [billingStatuses.UPDATED]: { icon: <CheckingIcon className="mr-2" />, text: t("reports.status.updated") },
    [billingStatuses.DENIED]: { icon: <RejectedIcon className="mr-2" />, text: t("reports.status.fix_report") },
    [billingStatuses.IN_REVIEW]: { icon: <CheckingIcon className="mr-2" />, text: t("reports.status.checking") },
    [billingStatuses.ACTIVE]: { icon: <CheckingIcon className="mr-2" />, text: t("reports.status.submitted") },
  };

  return (
    <div className="font-sm d-flex">
      {statusMapping[statusKey] && (
        <>
          {statusMapping[statusKey].icon}
          {statusMapping[statusKey].text}
        </>
      )}
    </div>
  );
};

Badge.propTypes = {
  billingStatus: PropTypes.string,
  state: PropTypes.string,
};

export default Badge;
