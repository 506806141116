import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { multiLangType } from '../../../types';
import SettlementsTable from '../../../components/settlements/SettlementsTable';

const Awaiting = ({ isFilters, settlements }) => {
  const { t } = useTranslation();

  if (!settlements.length && !isFilters) {
    return null;
  }

  return (
    <>
      <h4 className="font-md m-3">{t("settlements.awaiting")}</h4>
      <SettlementsTable settlements={settlements}/>
    </>
  );
};

Awaiting.propTypes = {
  isFilters: PropTypes.bool,
  settlements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      files: PropTypes.string,
      serviceId: PropTypes.number,
      serviceName: PropTypes.string,
      name: multiLangType,
      state: PropTypes.string,
      creation_date: PropTypes.string,
      due_date: PropTypes.string,
      billing: PropTypes.shape({
        refusal_comment: PropTypes.string,
        refusal_reason: PropTypes.string,
        status: PropTypes.string,
        report: PropTypes.object,
      }),
    })
  ),
};

export default Awaiting;
