import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';

import { Container } from '../../../styles/styled-components/GlobalStyles';
import { InvitationHeader, SpaceName, InvitationData, InvitationDataHeader, DecisionButton } from '../../../styles/styled-components/Invitation';
import { __env } from '../../../../envloader';
import axios, { accessTokenProvider } from '../../../../main/utils/axios/axiosInstance';
import * as notify from '../../../../main/utils/notify';
import Loading from '../../../../main/components/loading';
import useAxios from '../../../hooks/useAxios';
import { getSpaceListWithUsers } from '../../../actions/spacesActions';


const PrivateInvitation = ({ match, location }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [ isSending, setIsSending ] = useState(false);
  const [ intervalId, setIntervalId ] = useState(null);
  const [ decision, setDecision ] = useState(null);
  const [ isValid, setIsValid ] = useState(true);
  const [ isBadUser, setIsBadUser ] = useState(false);
  const loginData = useSelector(state => state.login.get('loginData'));

  const otherOptions = useMemo(() => (
    { __silentFor: [ { status: 400 }, { status: 403 } ] }
  ), []);

  const { data, isLoading, error } = useAxios(`${__env.BPM_API_URL}spaces/invitations/private/${match.params.id}/info${location.search}`,
    {
      sendOnInit: loginData ? true : false, 
      sendOnChange: loginData ? true : false, 
      otherOptions,
    });

  useEffect(() => {
    if (intervalId) {
      notify.success(
        t(`invitation.notification.success`),
        t(`invitation.notification.${decision}`)
      );

      if (decision === "accept") {
        accessTokenProvider.getInstance().obtainToken(true)
          .then(() => {
            history.push(`/spaces/${data.space_id}`); 
          });     
      }
      else {
        history.push('/');
      }
      setIsSending(false);
      clearInterval(intervalId);
      setIntervalId(null);
      setDecision(null);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [ data, history, t, decision, intervalId ]);

  const sendRequest = (decision) => {
    setIsSending(true);
    setDecision(decision);
    axios.put(
      `${__env.BPM_API_URL}spaces/invitations/private/${match.params.id}/${decision}${location.search}`,
      {},
      { __silentFor: [ { status: 400 }, { status: 403 } ] }
    ).then(() => {
      const id = setInterval(async () => {
        await accessTokenProvider.getInstance().obtainToken(true);
        const { data: isSpace } = await axios.get(
          `${__env.USERS_DATA_API_URL}api/users-data/spaces/${data.space_id}/`, 
          { __silentFor: [ { status: 403 } ] }
        );

        if (isSpace) {
          dispatch(getSpaceListWithUsers());
          setIntervalId(id);
        } 
      }, 2000);

    }).catch(err => {
      if (err.response.status === 403) {
        notify.error("", t('invitation.bad_user'));
        setIsBadUser(true);
      } 
      else {
        notify.error("", t('invitation.not_found'));
      }
      setIsValid(false);
      setIsSending(false);
    });
  };

  return (
    <Container>
      <InvitationHeader>{t('invitation.header_private')}</InvitationHeader>
      <Card>
        {isLoading ? <div className='m-3 d-flex justify-content-center'>
          <Loading />
        </div> : 
          !loginData ? 
            <div className='p-4 d-flex justify-content-center'>
              <InvitationHeader>{t('invitation.login_to_access')}</InvitationHeader>
            </div> :
            error ? 
              error.status === 403 ?
                <div className='p-4'>
                  {t('invitation.bad_user')}
                </div>
                :
                <div className='p-4'>
                  {t('invitation.error')}
                </div> 
              : 
              <>
                <CardHeader className='p-4'>
                  <Row className='m-0'>
                    <SpaceName>{data ? `${t('invitation.space_name')}: ${data.space_name}` : t('invitation.not_found')}</SpaceName>
                  </Row>
                  {data && 
                    <Row className='m-0 pt-3'>
                      <Col>
                        <InvitationDataHeader>{t('invitation.inviting_user')}</InvitationDataHeader>
                        <InvitationData><b>{t('invitation.name')}: </b>{data.inviting_user}</InvitationData>
                        <InvitationData><b>{t('invitation.email')}: </b>{data.inviting_user_email}</InvitationData>
                      </Col>
                    </Row>
                  }
                </CardHeader>
                <CardBody>
                  {isSending ? <div className='d-flex justify-content-center'>
                    <Loading />
                  </div> :
                    data ?
                      <Row className='m-0'>
                        {
                          isValid ? (
                            <>
                              <Col className='d-flex justify-content-center'>
                                <DecisionButton 
                                  color='danger' 
                                  block
                                  onClick={() => sendRequest('reject')}
                                >
                                  {t('invitation.reject')}
                                </DecisionButton>
                              </Col>
                              <Col className='d-flex justify-content-center'>
                                <DecisionButton 
                                  color='primary' 
                                  block
                                  onClick={() => sendRequest('accept')}
                                >
                                  {t('invitation.accept')}
                                </DecisionButton>
                              </Col>
                            </>
                          ) : (
                            <p style={{ textAlign: 'center' }}>{isBadUser ? t('invitation.bad_user') : t('invitation.not_found')}</p>
                          )
                        }
                      </Row>
                      :
                      <p>{t('invitation.not_found_text')}</p>
                  }
                </CardBody>
              </>
        }
      </Card>
    </Container>
  );
};

PrivateInvitation.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default PrivateInvitation;