import React from 'react';
import { Card } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Loading from '../../../../main/components/loading';
import Info from '../../../../assets/svg/settlements/info.svg';
import { HiglightedText } from '../../../styles/styled-components/UsersActionModal';
import { BillDetails, BillInfo, ResourceUsageTitle } from '../../../styles/styled-components/SettlementDetails';
import backendTranslation from './../../../utils/backendTranslation';
import { formatBasedOnValue } from '../../../utils/formatBasedOnValue';

const ResourcesUsage = ({ billingData, loadingResourcesUsage, resourcesUsage }) => {
  const { t } = useTranslation();
  const summary = billingData?.reduce((acc, billing) => acc + parseFloat(billing?.value), 0);

  function sumValuesByName(items) {
    if (!items) return null;

    const sumByNames = items?.reduce((acc, item) => {
      const key = JSON.stringify(item.name);
      if (!acc[key]) {
        acc[key] = { ...item, value: 0.0 };
      }
      acc[key].value += parseFloat(item.value);
      return acc;
    }, {});

    return Object.values(sumByNames);
  };

  const datesFormatted = (billDates) => {
    if (!billDates) return "";

    const [ year, month, day ] = billDates?.split('-');
    return `${day}/${month}/${year}`;
  };

  const uniqueBilling = sumValuesByName(billingData);

  if (loadingResourcesUsage) {
    return <Loading />;
  };

  if (!billingData) {
    return null;
  }

  return (
    <>
      <ResourceUsageTitle>{t('settlements_details.billing_period')}</ResourceUsageTitle>
      <Card style={{ padding: "32px 23px", marginBottom: "64px" }}>
        <BillInfo>
          {t('settlements_details.billing_period')} <b>{datesFormatted(resourcesUsage?.date_from)} - {datesFormatted(resourcesUsage?.date_to)}</b>
        </BillInfo>
        <BillDetails>
          <img src={Info} alt="info icon"/>
          <div>
            <div>{t('settlements_details.details_info')}
              <HiglightedText>{summary} PLN</HiglightedText>
            </div>
            <div style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
              {t('settlements_details.resource_usage')}
              {uniqueBilling?.map((billing, index) => (
                <div key={index}>
                  <HiglightedText>
                    {`${formatBasedOnValue(billing?.quantity)} ${backendTranslation(billing?.unit)} `}
                  </HiglightedText>
                  {`(${backendTranslation(billing?.name)})`}
                  {index !== uniqueBilling?.length - 1 && ','}
                </div>
              ))}
            </div>
          </div>
        </BillDetails>
      </Card>
    </>
  );
};

export default ResourcesUsage;

ResourcesUsage.propTypes = {
  billingData: PropTypes.array,
  loadingResourcesUsage: PropTypes.bool, 
  resourcesUsage: PropTypes.object
};