import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Price, TD, TH, TR, Thead } from '../../../styles/styled-components/SettlementDetails';
import backendTranslation from './../../../utils/backendTranslation';
import Loading from '../../../../main/components/loading';
import { formatBasedOnValue } from '../../../utils/formatBasedOnValue';

const PriceList = ({ billingData, loadingResourcesUsage }) => {
  const { t } = useTranslation();
  const summary = billingData?.reduce((acc, billing) => acc + parseFloat(billing?.value), 0);

  if (loadingResourcesUsage) {
    return <Loading />;
  };

  if (!billingData) {
    return null;
  }

  return (
    <>
      <Price>{t('settlements_details.cost_summary')}</Price>     
      <table style={{ width: "100%" }}>
        <Thead>
          <tr border>
            <TH>{t('settlements_details.price_list.parameter')}</TH>
            <TH>{t('settlements_details.price_list.unit')}</TH>
            <TH>{t('settlements_details.price_list.unit_price')}</TH>
            <TH>{t('settlements_details.price_list.vat')}</TH>
            <TH>{t('settlements_details.price_list.usage')}</TH>
            <TH>{t('settlements_details.price_list.total_amount')}</TH>
          </tr>
        </Thead>
        <tbody>
          {billingData?.map(billing =>
            <TR>
              <TD bold>{backendTranslation(billing?.name)}</TD>
              <TD>{backendTranslation(billing?.unit)}</TD>
              <TD>{billing?.price}</TD>
              <TD>23%</TD>
              <TD>{formatBasedOnValue(billing?.quantity)}</TD>
              <TD bold>{billing?.value}</TD>
            </TR>
          )}
          <TR border>
            <TD grey>{t('settlements_details.summary')}</TD>
            <td colspan="4"></td>
            <TD blue>{parseFloat(summary)}</TD>
          </TR>
        </tbody>
      </table>
    </>
  );
};

export default PriceList;

PriceList.propTypes = {
  billingData: PropTypes.array,
  loadingResourcesUsage: PropTypes.bool
};