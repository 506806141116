import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Card, Row, Col, Tooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { StyledLink, StyledHr } from '../styles/styled-components/GlobalStyles';
import { ClampedTextField } from '../styles/styled-components/ClampedTextField';
import { UserRole } from '../constants/roles';
import UserAvatar from '../components/UserAvatar';
import FavoriteButton from '../components/FavoriteButton';
import UserDetailsModal from '../components/UserDetailsModal';
import { __env } from '../../envloader';
import useAxios from '../hooks/useAxios';
import backendTranslation from '../utils/backendTranslation';
import { useEffect } from 'react';
import axios from '../../main/utils/axios/axiosInstance';

const Space = ({ space: { id, name, state, topic, type, users } }) => {
  const { t } = useTranslation();
  const ownRoles = useSelector((state) => state.spaces.ownRoles);
  const spaceRoles = ownRoles.get('data')?.filter(({ space_id }) => space_id === id).map(({ role }) => role) || [];
  const spaceImage = useSelector(state => state.spaces.spaceImg.get('data'));
  const { data: rolesResponse } = useAxios(`${__env.USERS_DATA_API_URL}api/users-data/spaces/${id}/user_roles`);

  const [ tooltipOpen, setTooltipOpen ] = useState(false);
  const [ userDetailsModalOpen, setUserDetailsModalOpen ] = useState(false);
  const [ selectedUser, setSelectedUser ] = useState(null);
  const [ title, setTitle ] = useState(null);
  const rolesToCheck = [ UserRole.OWNER, UserRole.USER_ADMIN, UserRole.FINANCIAL_ADMIN ];

  const mergeUserWithRoles = (user) => {
    const matchedRoles = rolesResponse?.items?.filter(role => role?.user_id === user?.id).map(role => role?.role);
    return matchedRoles?.length ? { ...user, roles: matchedRoles } : null;
  };

  useEffect(() => {
    if (type) {
      axios.get(`${__env.SERVICE_DATA_API_URL}api/public_contract_categories/?reference_id=${type}`)
        .then(response => {
          setTitle(response?.data?.items?.[0]?.title);
        });
    }
  }, [ type ]);

  const renderUsersList = () => (
    <Col>
      <div className='d-flex justify-content-end align-items-center'>
        {spaceRoles.some(role => rolesToCheck.includes(role)) && rolesResponse && (
          <>
            {users?.slice(0, 3).map((user) => (
              <div key={user.id} className='mr-2'>
                <UserAvatar 
                  username={user.first_name + " " + user.surname} 
                  spaceID={id} 
                  width="2.75rem" 
                  height="2.75rem" 
                  onClick={() => { setSelectedUser(mergeUserWithRoles(user)); setUserDetailsModalOpen(true); }}
                />
              </div>
            ))}
            {users?.length > 3 && 
            <>
              <StyledLink 
                id={`tooltip-${id}_users`} 
                to={`spaces/${id}/users`} 
                $unstyled
                style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}
              >
                <UserAvatar
                  username={`+${users?.length - 3}`} 
                  width="2.75rem"
                  height="2.75rem"
                  fontWeight="700"
                  fontSize="16px"
                  number={true}
                />
              </StyledLink>
              <Tooltip 
                placement='top-start' 
                isOpen={tooltipOpen} 
                target={`tooltip-${id}_users`} 
                toggle={() => setTooltipOpen(prev => !prev)}
              >{
                  users?.slice(3).map((user) => (
                    <div key={user.id} className='mr-2'>
                      {user.first_name} {user.surname}
                    </div>
                  ))
                }</Tooltip>
            </>}
          </>)}
        <div className='ml-5'>
          <FavoriteButton spaceId={id} id={id} type="spaces" />
        </div>
      </div>
    </Col>
  );

  const getImgIcon = (type) => {
    return spaceImage.find(e => e.reference_id === type)?.folder_icon;
  };

  return (
    <>  
      { selectedUser && 
        <UserDetailsModal
          isOpen={userDetailsModalOpen}
          toggle={() => setUserDetailsModalOpen(prev => !prev)}
          user={selectedUser}
        />
      }
      <li className='mb-4' data-testid='space'>
        <Card className='p-4'>
          <Row className='justify-content-between align-items-end pl-2'>
            <Col>
              <StyledLink to={`spaces/${id}`} $unstyled>
                <div className='d-flex align-items-center'>
                  <img src={getImgIcon(type)} alt='folder icon' className='mr-3' width="42" height="42"/>
                  <div>
                    <p className='font-xs m-0'>{backendTranslation(title)}</p>
                    <h5 className='font-md fontWeight-bold m-0'>{name}</h5>
                  </div>
                </div>
              </StyledLink>
            </Col>
            {renderUsersList()}
          </Row>
          <StyledHr mt='12px' mb='8px' />
          <Row className='justify-content-md-between align-items-end'>
            <Col md='9'>
              <ClampedTextField numOfLines={1} className='font-md m-0'>{topic}</ClampedTextField>
            </Col>
            <Col md='3' className='text-right'>
              <Link to={`spaces/${id}`} className='font-sm'>
                {t('show_more')} {'>'}
              </Link>
            </Col>
          </Row>
        </Card>
      </li>
    </>
  );
};

Space.propTypes = {
  space: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    state: PropTypes.string,
    topic: PropTypes.string,
    type: PropTypes.string,
    users: PropTypes.array
  }).isRequired,
  favorites: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string
  })),
  favoritesUpdate: PropTypes.func
};

export default Space;
