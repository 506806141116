import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';

import offerIcon from '../../assets/svg/offers/offer-1.svg';
import expiredIcon from '../../assets/svg/services/expired-icon.svg';
import terminatedIcon from '../../assets/svg/services/terminated-icon.svg';
import backendTranslation from '../utils/backendTranslation';
import FavoriteButton from './FavoriteButton';
import CollapsableTextField from './CollapsableTextField';
import { ImgContainer, TileCard, TileParentCol, TileTitile, TileTitileLink } from '../styles/styled-components/TileCard';
import { ReactComponent as Tag } from '../../assets/svg/services/tag.svg';
import CloseService from './CloseService';

// Static colors for tags
const tagColors = {
  blue: "#0096F6",
  green: "#529F37",
  red: "#D20724",
  yellow: "#FFA216"
};

const Service = ({ id, name, offerData, state, preview = false, inactive }) => {
  const match = useRouteMatch();
  const { t } = useTranslation();
  const spaceId = match.params.id;

  const statusTagColors = ({ blue, green, red, yellow } = tagColors) => {
    return {
      ACTIVE: blue,
      BLOCKED: red,
      ERROR: red,
      EXPIRED: red,
      INITIALIZED: green,
      NEW: green,
      PAUSED: yellow,
      TERMINATED: red
    };
  };

  const displayName = name || t('services_details.service');

  const chooseIcon = (state) => {
    return state === "TERMINATED" ? terminatedIcon : expiredIcon;
  };

  return (
    <TileParentCol key={id}>
      <TileCard>
        {!inactive &&
          <div className="d-flex justify-content-end">
            <FavoriteButton 
              spaceId={spaceId} 
              id={id} 
              type="services" 
            />
          </div>
        }
        {state === "TERMINATED" &&
          <CloseService id={id} spaceId={spaceId} displayName={displayName}/>
        }
        {!preview && 
          <TileTitile>
            <TileTitileLink to={`/spaces/${spaceId}/services/${id}`}>{displayName}</TileTitileLink>
          </TileTitile>
        }
        <ImgContainer
          inactive={inactive}
        >
          <img
            src={inactive ? chooseIcon(state) : (offerData?.offer__tile_icon || offerIcon)}
            alt={`${displayName} icon`}
            className="h-100"
          />
        </ImgContainer>
        {preview && 
          <TileTitile>
            {displayName}
          </TileTitile>
        }
        {preview && 
          <div className="text-right mt-5">
            <Link to={`/spaces/${spaceId}/services/${id}`}>
              {t("navigate")} {">"}
            </Link>
          </div>
        }
        {!preview &&
          <div className="text-center">
            <CollapsableTextField numOfLines={3}>
              {inactive 
                ? state === "TERMINATED"
                  ? t('services.inactive_communication_terminated')
                  : t('inactive_communication_expired')
                : backendTranslation(offerData?.short_description)}
            </CollapsableTextField>
          </div>
        }
        {!preview && 
          <div className='d-flex align-items-center'>
            <Tag stroke={statusTagColors()[state]} />
            <p className="ml-2 mb-0">{t(`service_status.${state.toLowerCase()}`)}</p>
          </div>
        }
      </TileCard>
    </TileParentCol>
  );
};

Service.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  spaceOffers: PropTypes.arrayOf(PropTypes.object),
  preview: PropTypes.bool,
  favorites: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    space: PropTypes.string
  })),
  offerData: PropTypes.shape({
    offer__tile_icon: PropTypes.string,
    short_description: PropTypes.string
  }),
  favoritesUpdate: PropTypes.func,
  state: PropTypes.string,
  inactive: PropTypes.bool
};

export default Service;
