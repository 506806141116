import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, PopoverBody, FormFeedback, Tooltip } from 'reactstrap';

import useClippy  from 'use-clippy';

import * as notify from '../../../../main/utils/notify';
import useAxios from '../../../hooks/useAxios';
import { __env } from '../../../../envloader';
import InvitedUsers from './InvitedUsers';
import axios from '../../../../main/utils/axios/axiosInstance';
import Loading from '../../../../main/components/loading';
import IconButton from '../../../components/IconButton';
import { InputDate, InputDiv, PopoverElem, CustomSwitch, CustomSwitchRound } from '../../../styles/styled-components/Invitation';
import { BtnTextRed,BtnTextBlue, BtnTextGrey, BtnBlue, BtnBlueOutline } from '../../../styles/styled-components/Button';
import { TextP, TextSpan, UnderlineText  } from '../../../styles/styled-components/TextElem';
import LoadingFullscreen from '../../../components/LoadingFullscreen';
import { RoundedInput } from '../../../styles/styled-components/GlobalStyles';

const PublicInvitations = ({ spaceId='', setIsLinkWithExpiredDate }) => {
  const { t } = useTranslation();
  const [ submitInProgress, setSubmitInProgress ] = useState(true);
  const [ link, setLink ] = useState(null);
  const [ useLinkExpiration, setUseLinkExpiration ] = useState(false);
  const [ useLinkExpirationModal, setUseLinkExpirationModal ] = useState(useLinkExpiration);
  const [ linkExpiration, setLinkExpiration ] = useState('');  
  const [ linkExpirationModal, setLinkExpirationModal ] = useState('');
  const [ modalSettingsOpen, setModalSettingsOpen ] = useState(false);
  const [ modalConfirmCancelOpen, setModalConfirmCancelOpen ] = useState(false);
  const [ showInputTooltip, isShowInputTooltip ] = useState(false);
  const [ showInvalidFeedbackExpirationDate, isShowInvalidFeedbackExpirationDate ] = useState(false);
  const [ , setClipboard ] = useClippy();
  const [ isLoadingFullscreen, setIsLoadingFullscreen ] = useState(false);
  const [ active, setActive ] = useState(false);
  const { 
    isLoading: isLoadingRequests, 
    error: errorRequests,  
    data: dataRequests, 
    refetch: refetchRequests 
  } = useAxios(`${__env.BPM_API_URL}spaces/invitations/public?spaceId=${spaceId}`);

  const {  
    isLoading: isLoadingHandling, 
    error: errorHandling, 
    data: dataHandling, 
    refetch: refetchHandling 
  } = useAxios(`${__env.BPM_API_URL}spaces/invitations/public/handling/${spaceId}`);

  const calculateDays = (expirationDate) =>{
    let difference = new Date(expirationDate).getTime() - new Date().getTime();
    return Math.ceil(difference / (1000 * 3600 * 24));
  };

  const timerRef = useRef(null);

  useEffect(() => {
    const id = setInterval(refetchHandling, 30000);
    return () => clearInterval(id);
  }, [ refetchHandling ]);

  useEffect(() => {
    if (!isLoadingRequests) {
      const linkData = dataRequests?.slice(-1)[0];
      if (linkData) {
        if (linkData.state === "EXPIRED") {
          setIsLinkWithExpiredDate(true);
          setSubmitInProgress(false);
        }
        else {
          if (linkData.id ) {
            let expDate;
            if (linkData.expiration_date) {
              expDate = linkData.expiration_date.slice(0, 10);
            }
            setLink(`${window.location.origin}/invitation/public/${linkData.id}/?secret=${linkData.secret}`);
            setLinkExpiration(expDate);
            setLinkExpirationModal(expDate);
            isShowInvalidFeedbackExpirationDate(calculateDays(expDate) <2);
          }
          setSubmitInProgress(false);
        }
      }
    }
    setSubmitInProgress(isLoadingRequests);
    return () => clearTimeout(timerRef.current);
  }, [ dataRequests, isLoadingRequests, setIsLinkWithExpiredDate, spaceId ]);

  useEffect(() => {
    if (link) {
      setClipboard(link);
    }
  }, [ setClipboard, link ]);


  const handleCopy = () => {
    setClipboard(link);
    notify.info(
      t('public_invitations.notifications.copy_to_clipboard_title'),
      t('public_invitations.notifications.copy_to_clipboard_body'),
    );
  };

  const handleCancel = async () => {
    setModalConfirmCancelOpen(false);
    setSubmitInProgress(true);
    if (dataRequests?.slice(-1)[0]?.id){
      await axios.delete(`${__env.BPM_API_URL}spaces/invitations/public/${dataRequests.slice(-1)[0].id}`)
        .then((res) => {
          if (res.status === 200) {
            notify.success('', t('public_invitations.notifications.success_link_was_canceled'));
          }
          else {
            notify.warning('', t('public_invitations.notifications.warrning_link_canceled_sth_wrong'));
          }
        }).finally(()=> {
          setLink(null);
          setSubmitInProgress(false);
        });
    }
  };
  
  const updateLinkParam = async () => {
    await axios.put(`${__env.BPM_API_URL}spaces/invitations/public/${dataRequests?.slice(-1)[0]?.id}`, {
      expiration_date:`${linkExpirationModal}T23:59:59.000Z`
    }, { __silentFor: 'all' }).then((res) => {
      if (res.status === 200) {
        setModalSettingsOpen(false);
        notify.success("", t('public_invitations.notifications.success_change_link_expiration'));
      }
      else {
        notify.warning('', t('public_invitations.notifications.warrning_expiration_date'));
      }      
    }).catch(e => {
      notify.error("", t('invitation.error_changed_expiration_date'));
      setModalSettingsOpen(false);
    }).finally(()=> {
      setIsLoadingFullscreen(false);
    });
  };

  const generateLink = async () => {
    await axios.post(`${__env.BPM_API_URL}spaces/invitations/public`, {
      space_id: spaceId,
      ...(useLinkExpiration && { expiration_date:`${linkExpiration}T23:59:59.000Z` })
    }, { __silentFor: 'all' }
    ).then((res) => {
      refetchHandling();
      refetchRequests();
      notify.info(
        t('public_invitations.notifications.success_title'),
        t('public_invitations.notifications.success_body')
      );
    }).catch(err => {
      if (err.response.status === 403) {
        timerRef.current = setTimeout(() => generateLink(), 5000);
      }
      else {
        notify.error("", t('invitation.error_generate'));
        setLink(null);
        setSubmitInProgress(false);
      }
    });
  };

  const activateExpiredLink = async (currLink) => {
    await axios.put(`${__env.BPM_API_URL}spaces/invitations/public/${currLink.id}`, 
      {}, { __silentFor: 'all' })
      .then((res) => {
        setIsLinkWithExpiredDate(false);
        refetchHandling();
        refetchRequests();
        notify.info(
          t('public_invitations.notifications.success_title'),
          t('public_invitations.notifications.success_body')
        );
      }).catch(err => {
        notify.error("", t('invitation.error_generate'));
        setLink(null);
        setSubmitInProgress(false);
      });
  };

  const onClickBtnGenerateLink = async () => {
    setSubmitInProgress(true);
    const currLink = dataRequests?.slice(-1)[0];
    if (currLink?.state === "EXPIRED") {
      await activateExpiredLink(currLink);
    }
    else {
      generateLink();
    }
  }; 

  const onChangeLinkSettings = (isClickSaveChanges) => {
    if (isClickSaveChanges) {
      if (linkExpirationModal === linkExpiration && useLinkExpiration === useLinkExpirationModal) {
        notify.info("", t('public_invitations.notifications.info_link_the_same_date'));
        setModalSettingsOpen(false);
      }
      else {
        setIsLoadingFullscreen(true);
        if (useLinkExpiration !== useLinkExpirationModal && !useLinkExpirationModal) {
          setLinkExpiration("");
        }
        else {
          setLinkExpiration(linkExpirationModal);
          isShowInvalidFeedbackExpirationDate(calculateDays(linkExpirationModal) < 2);
        }
        setUseLinkExpiration(useLinkExpirationModal);
        updateLinkParam();
      }
    }
    else {
      setModalSettingsOpen(false);
      setLinkExpirationModal(linkExpiration);
      setUseLinkExpirationModal(useLinkExpiration);
    }
  };

  const onChangeElemUseDateOfLinkExpiration = (switchValue) => {
    setUseLinkExpirationModal(switchValue);
    if (switchValue) {
      setLinkExpirationModal((new Date()).toISOString().slice(0, 10));
    }
    else {
      setLinkExpirationModal(null);
    }
  };
  
  return (
    <div className='pt-4'>
      {(!link) ?  
        <>
          { submitInProgress ? 
            <Loading /> :
            <> 
              <div className='mb-2'>{t('public_invitations.no_link_generated')}</div>
              <div className='pl-0 mt-4'>
                <BtnBlue onClick={onClickBtnGenerateLink} className="px-4 w-auto">
                  {t('public_invitations.generate_link')}
                </BtnBlue>
              </div>
            </> 
          }
        </>
        : 
        <>
          {(submitInProgress || isLoadingRequests) ? <Loading /> :
            <>
              {isLoadingFullscreen && <LoadingFullscreen/>}

              <div className='d-flex justify-content-end'>
                <IconButton
                  icon='settings'
                  className='w-auto'
                  onClick={() => setModalSettingsOpen(true)}
                  id="idPopoverSet"
                >
                  {t('public_invitations.btn_link_settings')}
                </IconButton>
              </div>
              <div>
                <TextP $fsize="sm" className='mb-2'>{t('public_invitations.title_active_link')}</TextP>
                <TextP $fsize="xs" $fcolor="lessDarkGrey" $fweight="normal" className='mb-4'>{t('public_invitations.subtitle_active_link')}</TextP>
              </div>
              <div className='d-flex align-items-center'>
                <InputDiv className='flex-grow-1 mr-3'>
                  <RoundedInput 
                    type="text"
                    value={link}
                    readOnly
                    id="tooltipInput"
                    invalid={showInvalidFeedbackExpirationDate}
                    radius="24px"
                  />
                  <Tooltip isOpen={showInputTooltip} target="tooltipInput" 
                    placement="top-start"
                    hideArrow={true}
                    toggle={() => isShowInputTooltip(prev => !prev)}>
                    {t('public_invitations.tooltip_how_to_change_date_expiration')}
                    <UnderlineText>{t('public_invitations.btn_link_settings')}</UnderlineText>
                  </Tooltip>
                  {showInvalidFeedbackExpirationDate &&
                    <FormFeedback>{t('public_invitations.feedback.expiration_date_will_expire_soon', { date: linkExpiration })}</FormFeedback>
                  }
                </InputDiv>
                <BtnBlue 
                  onClick={handleCopy}
                  className="w-auto ml-auto mr-3"
                  disabled={link === '' || !link}
                >
                  {t('public_invitations.copy_link')}
                </BtnBlue>
                <BtnBlueOutline onClick={() => setModalConfirmCancelOpen(true)} className="w-auto">
                  {t('public_invitations.cancel_link')}
                </BtnBlueOutline>
              </div>

              {dataRequests && dataRequests[0]  && dataHandling && dataHandling.length > 0 &&
              <>
                <hr className='w-100 m-0 my-4 mx-0'/>              
                <InvitedUsers 
                  isLoading={isLoadingHandling || isLoadingRequests} 
                  error={errorHandling || errorRequests}
                  data={dataHandling} 
                  refetchInvites={() => {
                    refetchHandling();
                    refetchRequests();
                  }}
                  invitationData={dataRequests && dataRequests[0]} />
              </>
              }
            </>
          }

          <PopoverElem  
            placement="bottom"  
            isOpen={modalSettingsOpen}  
            target="idPopoverSet"  
            toggle={() => setModalSettingsOpen(false)}
          >  
            <PopoverBody >    
              <>
                <TextP $fsize="sm" $fcolor="greyTitle">{t('public_invitations.btn_link_settings')}</TextP>
                <div className='d-flex justify-content-between align-items-center'>
                  <TextP $fsize="sm" className='mr-3 mb-0'>{t('public_invitations.link_expiration_date')}</TextP>
                  <CustomSwitch 
                    onClick={() => {
                      setActive(!active); 
                      onChangeElemUseDateOfLinkExpiration(!active);
                    }} 
                    active={active}
                    type='switch' 
                    id='collapse-all' 
                    name='collapse-all' 
                  >
                    <CustomSwitchRound 
                      active={active}  
                    />
                  </CustomSwitch>
                  <InputDate
                    type='date'
                    min={(new Date()).toISOString().slice(0, 10)}
                    value={linkExpirationModal ? linkExpirationModal : (new Date()).toISOString().slice(0, 10)}
                    onChange={(e) => setLinkExpirationModal(e.target.value)}
                    disabled={!useLinkExpirationModal} 
                    className='ml-auto text-center'
                  />
                </div>
                <hr className='w-100 mx-0 my-4'/>
                <div className='d-flex justify-content-end'>
                  <BtnTextGrey $fweight="500" className='mr-4' onClick={() => onChangeLinkSettings(false)}>
                    {t('users_filters_popover.cancel_button')}
                  </BtnTextGrey>
                  <BtnTextBlue $fweight="500" onClick={() => onChangeLinkSettings(true)}>
                    {t('users_filters_popover.apply_button')}
                  </BtnTextBlue>
                </div> 
              </>
            </PopoverBody>
          </PopoverElem>


          <Modal isOpen={modalConfirmCancelOpen} toggle={() => setModalConfirmCancelOpen(prev => !prev)} centered>
            <ModalBody>
              <TextP $fsize="md" $fweight="bold" className='d-flex justify-content-center flex-wrap'>
                {t('public_invitations.cancel_link_are_you_sure')}
                <TextSpan $fcolor="blueLink" $fweight="bold" className="pl-1">
                  {t('public_invitations.cancel_link_title_phrase_bold_blue')}
                </TextSpan>
                ?
              </TextP>
              <TextP $fsize="sm" $fweight="normal" $fcolor="grey2" className='d-flex justify-content-center flex-wrap text-center mx-5 mb-5'>
                {t('public_invitations.cancel_link_desc_part1')}
                <TextSpan $fcolor="grey2" $fweight="bold" className='ml-1'>
                  {t('public_invitations.cancel_link_desc_phrase_bold_black')}
                </TextSpan>
                {t('public_invitations.cancel_link_desc_part2')}
              </TextP>
              <div className='d-flex justify-content-around'>
                <BtnTextGrey $fweight="500" className='mr-4' onClick={() => setModalConfirmCancelOpen(false)}>
                  {t('users_filters_popover.cancel_button')}
                </BtnTextGrey>
                <BtnTextRed $fweight="500" onClick={handleCancel}>
                  {t('public_invitations.cancel_link')}
                </BtnTextRed>
              </div>
            </ModalBody>
          </Modal>

        </>
      }
    </div>
  );
};

PublicInvitations.propTypes = {
  spaceId: PropTypes.string.isRequired,
  setIsLinkWithExpiredDate: PropTypes.func
};

export default PublicInvitations;
