import React, { useState, useMemo } from 'react';
import { Alert } from 'reactstrap';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import ServiceRow from './ServiceRow';
import ServicesInProgress from './ServicesInProgress';
import useCurrentSpace from '../../hooks/useCurrentSpace';
import useAxios from '../../hooks/useAxios';
import { __env } from '../../../envloader';
import Loading from '../../../main/components/loading';
import iconNoServices from '../../../assets/svg/services/no-active-services.svg';
import { SectionNoData, ImgContainer, TitleNewData, TextNoData, SubTitleNewData } from '../../styles/styled-components/ContainerNoData';
import { Link } from 'react-router-dom';
import InfoBox from '../../components/InfoBox';
import IconInfo from '../../../assets/svg/common/blue-info.svg';
import { Arrow, TileParentRow } from '../../styles/styled-components/TileCard';
import { useSelector } from 'react-redux';
import Breadcrumbs  from './Breadcrumbs';
import { AppTitle } from '../../utils/componentsLocal';

const Services = ({ match }) => {
  const { t } = useTranslation();
  const spaceId = match.params.id;
  const { space } = useCurrentSpace(match);
  const [ servicesInProgressExists, setServicesInProgressExists ] = useState(undefined);

  const {
    data: spaceUserOffersData
  } = useAxios(`${__env.SERVICE_DATA_API_URL}api/spaces/${spaceId}/user_offers`);

  const selectDates = state => ({
    services: state.services.servicesList.get('data'),
    isLoading: state.services.servicesList.get('isLoading'),
    error: state.services.servicesList.get('error'),
  });  
  const { services, isLoading, error } = useSelector(selectDates);
  
  const { activeServices, inactiveServices } = useMemo(() => {
    const enrichedServices = services?.map(service => {
      const foundItem = spaceUserOffersData?.items?.find(ele => ele.id === service.parameters.bpm_params_offer_id?.value);
      
      const offerData = {
        offer__tile_icon: foundItem?.offer__tile_icon,
        short_description: foundItem?.short_description
      };
    
      return {
        ...service,
        offerData
      };
    });
    const active = enrichedServices?.filter(item => ![ "TERMINATED", "EXPIRED" ].includes(item.state));
    const inactive = enrichedServices?.filter(item => [ "TERMINATED", "EXPIRED" ].includes(item.state));

    return { 
      activeServices: active, 
      inactiveServices: inactive 
    };
  }, [ services, spaceUserOffersData ]);
  
  const [ isOpen, setIsOpen ] = useState(false);
  const [ isActive, setIsActive ] = useState(false);
  const isLoadingServices = activeServices.some(service => !spaceUserOffersData?.items?.find(ele => ele.id === service.parameters.bpm_params_offer_id?.value));
  const dataLocations = useMemo(() => ([
    { title: space?.name, location: `/spaces/${space?.id}` },
    { title: t('nav_items.services') },
  ]), [ space?.name, space?.id, t ]);

  return (
    <>
      <Breadcrumbs  dataLocations={dataLocations} />
      <Helmet>
        <title>{space && t('page_titles.spaces.services', { space: space.name, USERS_UI_NAME: AppTitle() })}</title>
      </Helmet>
      <section>
        { error ? (
          <Alert color='danger'>{t('services.api_error')}</Alert>
        ) : (
          (isLoading || servicesInProgressExists === undefined) ? (
            <Loading />
          ) : (
            services?.length === 0 || activeServices?.length === 0 ? (
              !servicesInProgressExists ? (
                <div>
                  <TextNoData>
                    {t('services.no_active_services')}
                  </TextNoData>
                  <SectionNoData>
                    <ImgContainer src={iconNoServices} alt='' className='mb-3' />
                    <TitleNewData>
                      <Trans i18nKey="services.active_service_to_access_it" />
                    </TitleNewData>
                    <SubTitleNewData>
                      <Trans i18nKey='services.go_to_offer_selection'>
                        Go to <Link to={`/spaces/${space?.id}/offers/`}>click</Link> to sign a new contract.
                      </Trans>
                    </SubTitleNewData>
                  </SectionNoData>
                </div>
              ) : (
                <InfoBox color="blue" iconPath={IconInfo} iconAlt="info">
                  <Trans i18nKey='services.without_services'> 
                    nie posiada <b>lista</b> teskt <Link to={`/spaces/${space?.id}/offers/`}>Ofert</Link> aby aktywowac
                  </Trans>
                </InfoBox>
              )
            ) : (
              <div>
                <div className="mt-5 mb-4">
                  <h5>{t('services.header')}</h5>
                </div>
                { isLoadingServices ?
                  <Loading />
                  :
                  <TileParentRow>
                    {activeServices.map(({ id, name, state, offerData, parameters: { bpm_params_offer_id } }) => (
                      <ServiceRow
                        id={id}
                        key={id}
                        state={state}
                        offerData={offerData}
                        offer_id={bpm_params_offer_id?.value}
                        name={name}
                      />
                    ))}
                  </TileParentRow>
                }
              </div>
            )))
        }
      </section>
      {!isLoading &&
        <ServicesInProgress 
          spaceId={match.params.id} 
          withoutServices={services.length === 0} 
          setServicesInProgressExists={setServicesInProgressExists}
        />
      }
      {!isLoading && inactiveServices.length > 0 &&
        <>
          <button
            onClick={() => {setIsActive(true); setIsOpen(!isOpen);}} 
            className="mt-5 mb-4 border-0 bg-transparent d-flex align-items-center"
          >
            <h5 className='m-0'>{t('services.inactive')}</h5>
            <Arrow open={isOpen} active={isActive} className="ml-3"/>
          </button>
          { ((servicesInProgressExists === undefined)) ? (
            <Loading />
          ) : !isOpen &&
                <TileParentRow>
                  {inactiveServices.map(({ id, name, parameters: { bpm_params_offer_id }, state, offerData }) => (
                    <ServiceRow
                      id={id}
                      key={id}
                      state={state}
                      offer_id={bpm_params_offer_id?.value}
                      offerData={offerData}
                      name={name}
                      inactive={true}
                    />
                  ))}
                </TileParentRow>
          }
        </>
      }
    </>
  );
};

Services.propTypes = {
  match: PropTypes.object.isRequired
};

export default Services;
