import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { compose } from 'redux';
import { Card } from 'reactstrap';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import useCurrentSpace from '../../hooks/useCurrentSpace';
import Loading from '../../../main/components/loading';
import { ErrorBoundary } from '../../../main/hoc/errorboundary';
import RaportDetails from './RaportDetails';
import useAxios from '../../hooks/useAxios';
import { __env } from '../../../envloader';
import Breadcrumbs  from './Breadcrumbs';
import axiosInstance from '../../../main/utils/axios/axiosInstance';
import ResourcesUsage from '../../components/settlements/settlementsDetails/ResourcesUsage';
import PriceList from '../../components/settlements/settlementsDetails/PriceList';
import Header from '../../components/settlements/settlementsDetails/Header';
import UsageDatails from '../../components/settlements/settlementsDetails/UsageDetails';
import offerIcon from '../../../assets/svg/offers/offer-1.svg';
import { AppTitle } from '../../utils/componentsLocal';

const SettlementsDetails = ({ match }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { space } = useCurrentSpace(match);
  const spaceLocation = `/spaces/${space?.id}`;
  const spaceId = match.params.id;
  const segments = location.pathname?.split('/');

  const [ icon, setIcon ] = useState(false);
  const [ resourcesUsage, setResourcesUsage ] = useState(null);
  const [ loadingResourcesUsage, setLoadingResourcesUsage ] = useState(true);
  
  const dataLocations = useMemo(() => ([
    { title: space?.name, location: spaceLocation },
    { title: t('nav_items.settlements'), location: `${spaceLocation}/settlements` },
    { title: t('nav_items.settlements_details') }
  ]), [ space?.name, spaceLocation, t ]);

  const silent = useMemo(() => ({ __silentFor: [ { status: 404 } ], }), []);

  const { isLoading, data } = useAxios(`${__env.SERVICE_DATA_API_URL}api/billings/${match.params.settlementId}`);
  const settlement = data?.item;

  const { isLoading: isLoadingBilling, data: billing } = useAxios(
    `${__env.BPM_API_URL}payment/${settlement?.id}/`,
    {
      sendOnInit: false,
      otherOptions: silent,
    }
  );

  const getIcon = useCallback(() => {
    axiosInstance.get(`${__env.USERS_DATA_API_URL}api/users-data/services/${segments?.[segments?.length - 2]}`)
      .then(response => {
        const value = response.data?.parameters.bpm_params_offer_id.value;
        axiosInstance.get(`${__env.SERVICE_DATA_API_URL}api/spaces/${spaceId}/user_offers`)
          .then(response => {
            setIcon(response.data?.items?.find(offer => offer.id === value)?.offer__tile_icon || offerIcon);
          });
      });
  }, [ segments, spaceId ]);

  const getResourcesUsage = useCallback(() => {
    axiosInstance(`${__env.SERVICE_DATA_API_URL}api/resources_usage/${settlement?.resources_usage_id}/`)
      .then(res => { setResourcesUsage(res.data?.item); setLoadingResourcesUsage(false); });
  }, [ settlement?.resources_usage_id ]);

  useEffect(() => {
    settlement?.resources_usage_id && getResourcesUsage();
  }, [ getResourcesUsage, settlement?.resources_usage_id ]);

  useEffect(() => {
    getIcon();
  }, [ getIcon ]);

  if (!space || isLoading || isLoadingBilling || !icon) {
    return (
      <>
        <Breadcrumbs dataLocations={dataLocations} />
        <Loading />
      </>
    );
  }

  return (
    <>
      <Breadcrumbs dataLocations={dataLocations} />
      <Helmet>
        <title>
          {t("page_titles.spaces.settlements", { space: space.name, USERS_UI_NAME: AppTitle() })}
        </title>
      </Helmet>
      <Card className="p-4 m-2">
        <Header settlement={settlement} status={billing?.status} chooseIcon={icon}/>
        <ResourcesUsage 
          billingData={settlement?.billing_data?.items} 
          loadingResourcesUsage={loadingResourcesUsage || isLoading} 
          resourcesUsage={resourcesUsage}
        />
        <PriceList 
          billingData={settlement?.billing_data?.items} 
          loadingResourcesUsage={loadingResourcesUsage}
        />
        <UsageDatails 
          resourcesUsage={resourcesUsage} 
          loadingResourcesUsage={loadingResourcesUsage}
        />
      </Card>
      <RaportDetails report={billing?.report} />
    </>
  );
};

SettlementsDetails.propTypes = {
  match: PropTypes.object.isRequired, // HOC
};

export default compose(
  ErrorBoundary((props) => props.t("settlements.error_boundary"))
)(SettlementsDetails);
