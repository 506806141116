import axios from '../../main/utils/axios/axiosInstance';
import { __env } from '../../envloader';

const namespace = '@PRACELAB_SERVICES/';

export const FETCH_SERVICES_LIST_REQUEST = namespace + 'FETCH_SERVICES_LIST_REQUEST';
export const FETCH_SERVICES_LIST_SUCCESS = namespace + 'FETCH_SERVICES_LIST_SUCCESS';
export const FETCH_SERVICES_LIST_ERROR = namespace + 'FETCH_SERVICES_LIST_ERROR';

export const getServices = (id) => async (dispatch) => {
  dispatch({ type: FETCH_SERVICES_LIST_REQUEST });    
  try {
    const { data } = await axios.get(`${__env.USERS_DATA_API_URL}api/users-data/services/?space_id=${id}&hidden=false`);
    dispatch({
      type: FETCH_SERVICES_LIST_SUCCESS,
      payload: data?.items
    });
  } 
  catch (err) {
    dispatch({
      type: FETCH_SERVICES_LIST_ERROR,
      payload: err.response ? err.response.data : err.message
    });
  }
};

export const refreshServices = (id, refetch) => (dispatch, getState) => {
  const currentData = getState().services.servicesList.get('data');
  axios.get(`${__env.USERS_DATA_API_URL}api/users-data/services/?space_id=${id}`)
    .then(response => {
      if (JSON.stringify(currentData) !== JSON.stringify(response.data?.items)) {
        dispatch({ type: FETCH_SERVICES_LIST_REQUEST });
        refetch();
        dispatch({
          type: FETCH_SERVICES_LIST_SUCCESS,
          payload: response?.data?.items
        });
      }
    })
    .catch(err => {
      dispatch({
        type: FETCH_SERVICES_LIST_ERROR,
        payload: err.response ? err.response.data : err.message
      });
    });
};