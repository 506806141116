import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';

import { UsersCard } from '../../styles/styled-components/SpaceUsers';
import { HiglightedText } from '../../styles/styled-components/UsersActionModal';
import { getUsersWithRoles, changeUsersRoles, unselectAllUsers, removeUsersFromSpace, refreshUsers } from '../../actions/usersActions';
import Loading from '../../../main/components/loading';
import AdminActions from '../../components/AdminActions';
import ActiveFilters from './ActiveFilters';
import UsersActionModal from '../../components/UsersActionModal';
import UserRolesInfo from '../../components/UserRolesInfo';
import SearchUsers from './SearchUsers';
import ActiveSearchFilter from './ActiveSearchFilter';
import { UserRole } from '../../constants/roles';
import UsersTable from './UsersTable';
import useCurrentSpace from '../../hooks/useCurrentSpace';
import SelectAllUsersCheckbox from './SelectAllUsersCheckbox';
import Breadcrumbs  from './Breadcrumbs';
import { AppTitle } from '../../utils/componentsLocal';

const SpaceUsers = ({ match }) => {
  const [ activeModal, setActiveModal ] = useState('');
  const spaceId = match.params.id;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { space, roles } = useCurrentSpace(match);

  const users = useSelector((state) => state.users.get('allUsers').get('data'));
  const usersLoading = useSelector((state) => state.users.get('allUsers').get('isLoading'));
  const usersError = useSelector((state) => state.users.get('allUsers').get('error'));
  const usersCount = useSelector((state) => state.users.get('usersCount'));
  const selectedUsers = useSelector((state) => state.users.get('selectedUsers'));
  
  const dataLocations = useMemo(() => ([
    { title: space?.name, location: `/spaces/${space?.id}` },
    { title: t('nav_items.users') }
  ]), [ space?.name, space?.id, t ]) ;

  useEffect(() => {
    dispatch(getUsersWithRoles(spaceId));
  }, [ dispatch, spaceId ]);

  useEffect(() => {
    const refresh = setInterval(() => dispatch(refreshUsers(spaceId)), 10000);
    return () => clearInterval(refresh);
  }, [ dispatch, spaceId ]);

  const onDeleteUsers = (result) => {
    if (result) {
      dispatch(removeUsersFromSpace({
        userIds: selectedUsers.toJS(),
        spaceId
      }));
      dispatch(unselectAllUsers());
    }

    setActiveModal('');
  };

  const onGrantEndUserRoles = (result) => {
    if (result) {
      const usersWithoutEndUserRole = users.items.filter(({ roles }) => !roles.includes(UserRole.END_USER)).map(({ id }) => id);
      dispatch(changeUsersRoles({
        spaceId,
        userIds: selectedUsers.toJS().filter(id => usersWithoutEndUserRole.includes(id)),
        roles: [ UserRole.END_USER ],
      }));
      dispatch(unselectAllUsers());
    }

    setActiveModal('');
  };

  const onRemoveEndUserRoles = (result) => {
    if (result) {
      const usersWithEndUserRole = users.items.filter(({ roles }) => roles.includes(UserRole.END_USER)).map(({ id }) => id);
      dispatch(changeUsersRoles({
        spaceId,
        userIds: selectedUsers.toJS().filter(id => usersWithEndUserRole.includes(id)),
        roles: [ UserRole.END_USER ],
      }));
      dispatch(unselectAllUsers());
    }

    setActiveModal('');
  };
  
  if (!space || !roles || !users) {
    return <Loading />;
  }

  return (
    <>
      <Breadcrumbs  dataLocations={dataLocations} />
      <section>
        <Helmet>
          <title>{t('page_titles.spaces.users', { space: space.name, USERS_UI_NAME: AppTitle() })}</title>
        </Helmet>
        <div className='d-flex justify-content-between align-items-center my-5'>
          <div>
            <h5 className='font-lg'>{t('space_users.users_in_space')}:{' '}<span>{usersCount}</span></h5>
            <p>{t('space_users.grant_role_in_space')}</p>
          </div>
          <Link to={`/spaces/${match.params.id}/invitations`}>
            <Button disabled={!roles.find(role => role === UserRole.USER_ADMIN)} color='secondary'>{t('space_users.invite_user')}</Button>
          </Link>
        </div>
        <UserRolesInfo spaceName={space.name} roles={roles} />
        <UsersCard>
          <SearchUsers />
          <Row>
            <Col md={3}>
              <SelectAllUsersCheckbox/>
            </Col>
            <Col>
              {selectedUsers.size > 0 && (
                <AdminActions
                  onGrantRoleClick={() => setActiveModal('grantEndUserRoles')}
                  onRevokeRoleClick={() => setActiveModal('removeEndUserRoles')}
                  onDeleteUserClick={() => setActiveModal('deleteUsers')}
                />
              )}
            </Col>
          </Row>
          <hr className="my-1 mx-3"/>
          <ActiveFilters />
          <ActiveSearchFilter />
          {usersLoading &&
            <Loading />
          }
          {!usersError && <UsersTable disabled={usersLoading} />}
        </UsersCard>
        <UsersActionModal
          isOpen={activeModal === 'deleteUsers'}
          toggle={onDeleteUsers}
          bodyText={t('users_actions_modals.body_text')}
          cancelButtonText={t('users_actions_modals.cancel_button')}
          confirmButtonText={t('users_actions_modals.delete_users_button')}
          confirmButtonColor='red'
        >
          <Trans i18nKey='users_actions_modals.delete_users_text' count={selectedUsers.size}>
            Are you sure you want delete <HiglightedText>{{ count: selectedUsers.size }}</HiglightedText> users?
          </Trans>
        </UsersActionModal>
        <UsersActionModal
          isOpen={activeModal === 'grantEndUserRoles'}
          toggle={onGrantEndUserRoles}
          bodyText={t('users_actions_modals.body_text')}
          cancelButtonText={t('users_actions_modals.cancel_button')}
          confirmButtonText={t('users_actions_modals.grant_role_button')}
          confirmButtonColor='blue'
        >
          <Trans i18nKey='users_actions_modals.grant_end_user_roles_text' count={selectedUsers.size}>
            Are you sure you want revoke end user role from <HiglightedText>{{ count: selectedUsers.size }}</HiglightedText> users?
          </Trans>
        </UsersActionModal>
        <UsersActionModal
          isOpen={activeModal === 'removeEndUserRoles'}
          toggle={onRemoveEndUserRoles}
          bodyText={t('users_actions_modals.body_text')}
          cancelButtonText={t('users_actions_modals.cancel_button')}
          confirmButtonText={t('users_actions_modals.revoke_role_button')}
          confirmButtonColor='blue'
        >
          <Trans i18nKey='users_actions_modals.revoke_end_user_roles_text' count={selectedUsers.size}>
            Are you sure you want revoke end user role from <HiglightedText>{{ count: selectedUsers.size }}</HiglightedText> users?
          </Trans>
        </UsersActionModal>
      </section>
    </>
  );
};

SpaceUsers.propTypes = {
  match: PropTypes.object.isRequired, // HOC
};

export default SpaceUsers;
