import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';

import { Details, DetailsSpace, HeaderSpace, Label, Title } from '../../../styles/styled-components/SettlementDetails';
import backendTranslation from './../../../utils/backendTranslation';
import Badge from '../../../components/settlements/Badge';

const Header = ({ chooseIcon, settlement, status }) => {
  const { t } = useTranslation();

  const [ isTextOverflow, setIsTextOverflow ] = useState(false);
  const [ isCursor, setIsCursor ] = useState(false);

  const checkTextOverflow = (element) => {
    const isEllipsis = element.offsetHeight < element.scrollHeight;
    setIsTextOverflow(isEllipsis);
    setIsCursor(isEllipsis);
  };

  return (
    <HeaderSpace>
      <DetailsSpace>
        <img src={chooseIcon} alt="offer icon w-100" />
        <Details>
          <Title isCursor={isCursor} id="TitleSettlementsDetails" onMouseEnter={(event) => checkTextOverflow(event.target)}>
            {backendTranslation(settlement?.name)}
          </Title>
          { isTextOverflow &&
            <UncontrolledTooltip placement="bottom-start" target="TitleSettlementsDetails">
              {backendTranslation(settlement?.name)}
            </UncontrolledTooltip>
          }
          
          <div>{settlement?.service_group}</div>
        </Details>
      </DetailsSpace>
      <div className="float-right"> 
        <Label>{t("settlements_details.status")}</Label>
        <b>
          <Badge
            state={settlement?.state} /* no data, return and implement in the future!!! */
            billingStatus={status}
          />
        </b>
      </div>
    </HeaderSpace>
  );
};

export default Header;

Header.propTypes = {
  chooseIcon: PropTypes.string,
  settlement: PropTypes.object, 
  status: PropTypes.string
};