import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import {
  ActiveFilters,
  ActiveFiltersContainer,
  ActiveFiltersTitle,
  BadgeContainer,
  ClearAllButton,
  SpaceFilters
} from '../../styles/styled-components/Settlements.js';
import { deleteIcon } from '../../../assets/svg/settlements/icons.js';

export const ActiveFiltes = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const searchParams = new URLSearchParams(location.search);
  const getAllFilters = searchParams.getAll('filter');
  const getEndDate = searchParams.get('endDate');
  const getStartDate = searchParams.get('startDate');

  const handleBadgeClick = (key, filter) => {
    searchParams.delete(key, filter);

    history.push({ search: searchParams.toString() });
  };

  const clearAllSearchParams = () => {
    const newSearchParams = new URLSearchParams();

    history.push({ search: newSearchParams.toString() });
  };

  if (!searchParams.size) {
    return null;
  }

  return (
    <ActiveFiltersContainer>
      <SpaceFilters>
        <ActiveFilters>
          {!!getAllFilters.length &&
            <>
              <ActiveFiltersTitle>{t('settlements.active_filters')}</ActiveFiltersTitle>
              { getAllFilters.map((filter, index) =>
                <BadgeContainer 
                  key={`${filter}-${index}`}
                  onClick={() => handleBadgeClick('filter', filter)}
                >
                  {filter}
                  <img src={deleteIcon} alt='delete-icon'/>
                </BadgeContainer>
              )}
            </>
          }
        </ActiveFilters>
        <ActiveFilters>
          {(getStartDate || getEndDate) &&
            <>
              <ActiveFiltersTitle>{t('settlements.table_head.date')}:</ActiveFiltersTitle>
              {getStartDate &&
                <BadgeContainer onClick={() => handleBadgeClick('startDate', getStartDate)}>
                  {t('settlements.calendar_from')} {getStartDate}
                  <img src={deleteIcon} alt='delete-icon'/>
                </BadgeContainer>
              }
              {getEndDate &&
                <BadgeContainer onClick={() => handleBadgeClick('endDate', getEndDate)}>
                  {t('settlements.calendar_to')} {getEndDate}
                  <img src={deleteIcon} alt='delete-icon'/>
                </BadgeContainer>
              }
            </>
          }
        </ActiveFilters>
      </SpaceFilters>
      <ClearAllButton onClick={() => clearAllSearchParams()}>{t('settlements.clear_all_filters')}</ClearAllButton>
    </ActiveFiltersContainer>

  );
};

export default ActiveFiltes;